<template>
  <div class="">
    <div class="pt-4 px-7 bg-white flex justify-between">
      <p class="font-semibold">Session Management</p>
      <div class="flex gap-2">
        <base-button
          label="Working Days"
          icon="calendar"
          :primary="false"
          class="px-2 bg-gray-100 flex-shrink-0"
          code="ses-wdy"
          @click="handleWorkingDays"
        />
        <base-button
          label="Add Sessions"
          icon="cog"
          class="px-2 rounded-default"
          @click="handleAdd"
          code="ses-add"
        />
        <base-button
          label="Generate"
          icon="cog"
          class="px-2 rounded-default"
          @click="handleGenerate"
          code="ses-gen"
        />
      </div>
    </div>

    <week-session-view
      ref="sessions"
      :WORKING_DAYS="WORKING_DAYS"
      v-if="authCodes.includes('ses-view')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WeekSessionView from './components/WeekSessionView.vue';
import eventBus from '@/eventBus.js';

export default {
  data() {
    return {
      working_days: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
      },
    };
  },
  components: {
    WeekSessionView,
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    WORKING_DAYS() {
      return Object.keys(this.working_days)
        .map((day) => {
          if (this.working_days[day]) {
            return {
              label: day.toUpperCase(),
              value: day,
            };
          }
        })
        .filter(Boolean);
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    handleWorkingDays() {
      eventBus.$emit('open-modal', {
        modalId: 38,
        cb: this.fetchWorkingDays,
      });
    },

    handleGenerate() {
      eventBus.$emit('open-modal', { modalId: 26 });
    },

    async fetchSessions() {
      console.log('fetchSessions: ');
      await this.$refs.sessions.fetchSession();
    },

    handleAdd() {
      let componentProps = {
        WORKING_DAYS: this.WORKING_DAYS,
      };
      eventBus.$emit('open-modal', {
        modalId: 65,
        cb: this.fetchSessions,
        componentProps,
      });
    },

    async fetchWorkingDays() {
      let response = await this.request({
        method: 'get',
        url: 'school/config/',
      });

      if (response && !response.error) {
        if (response.record.working_days)
          this.working_days = response.record.working_days;
      } else {
        eventBus.$emit('open-toast', {
          message: response.message,
          error: true,
        });
      }
    },
  },
  async created() {
    await this.fetchWorkingDays();
  },
};
</script>

<style></style>
