<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title> {{ isAdd ? 'Add' : 'Remove' }} Adjustment </template>
    <template>
      <div class=" ">
        <div class="flex justify-center space-x-2 items-center">
          <base-radio
            type="text"
            label="Adjustment Type"
            bordered
            required
            :min="0"
            :items="ADJUSTMENT_OPTIONS"
            v-model="Form.adjustment_type"
          />
        </div>

        <div class="grid grid-cols-1 gap-5">
          <base-select
            bordered
            searchable
            label="Book"
            :items="BOOK_OPTIONS"
            placeholder="book"
            v-model="Form.book"
            :errors="errors.book"
          />
          <div class="mt-1 col-span-2" v-if="Form.adjustment_type == 'add'">
            <div class="pb-2">Copies</div>
            <div class="space-y-3">
              <div class="grid grid-cols-12 items-center gap-1">
                <div class="col-span-6">ISBN</div>
                <div class="col-span-4">Health %</div>
              </div>
              <div
                v-for="(item, i) in Form.new_book_copy"
                :key="i"
                class="grid grid-cols-12 items-center gap-4 space-y-2"
              >
                <base-input
                  placeholder="isbn"
                  type="text"
                  class="col-span-6"
                  v-model="Form.new_book_copy[i].isbn"
                  :errors="errors.isbn"
                  caps
                />

                <base-select
                  bordered
                  :items="HEALTH_OPTIONS"
                  placeholder="health"
                  class="col-span-4"
                  v-model="Form.new_book_copy[i].health"
                  :errors="errors.health"
                />
                <div
                  class="cursor-pointer text-primary bg-gray-200 hover:bg-opacity-50 p-2 rounded-lg"
                  @click="addCopy(i)"
                >
                  <icon name="plus" :size="14" class="" />
                </div>

                <div
                  v-if="i > 0"
                  class="cursor-pointer text-error bg-gray-200 hover:bg-opacity-50 p-2 rounded-lg"
                  @click="removeCopy(i)"
                >
                  <icon name="trash-alt" :size="12" class="" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-2 space-y-2" v-else>
            <base-multi-select
              label="Copies"
              placeholder="copies"
              v-model="Form.book_copy"
              :errors="errors.book_copy"
              :items="COPY_OPTIONS"
            />

            <base-select
              bordered
              searchable
              label="Reason"
              :items="REASON_OPTIONS"
              placeholder="reason"
              v-model="Form.reason"
              :errors="errors.reason"
            />
          </div>

          <base-input
            class="col-span-2"
            label="Description"
            placeholder="description"
            v-model="Form.description"
            :errors="errors.description"
          />

          <switch-button
            class="col-span-2"
            label="Is Active"
            v-model="Form.status"
          />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import SwitchButton from '@/components/base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm'],
  mixins: [validation],
  data() {
    return {
      Form: {
        book: '',
        book_copy: [],
        new_book_copy: [
          {
            isbn: '',
            health: '',
          },
        ],
        adjustment_type: 'add',
        reason: '',
        description: '',
      },
      bookList: [],
      copyList: [],

      errors: {},
      working: false,
      meta: [
        { key: 'Form.book', label: 'Book' },
        { key: 'Form.adjustment_type', label: 'Adjustment Type' },
        { key: 'Form.book_copy', label: 'Copies' },
        { key: 'Form.new_book_copy', label: 'New Copies' },
        { key: 'Form.reason', label: 'Reason' },
      ],
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    isAdd() {
      if (this.Form.adjustment_type == 'add') return true;
      else return false;
    },
    ADJUSTMENT_OPTIONS() {
      return [
        {
          label: 'Add',
          value: 'add',
        },
        {
          label: 'Remove',
          value: 'remove',
        },
      ];
    },
    REASON_OPTIONS() {
      return [
        {
          label: 'Missing',
          value: 'missing',
        },
        {
          label: 'Damaged',
          value: 'damaged',
        },
      ];
    },
    HEALTH_OPTIONS() {
      return [
        { label: 'New', value: 'new' },
        { label: 'Moderate', value: 'moderate' },
        { label: 'Old', value: 'old' },
      ];
    },

    BOOK_OPTIONS() {
      return this.bookList.map((book) => ({
        label: book.name + ' - ' + book.author,
        value: book._id,
      }));
    },

    COPY_OPTIONS() {
      return this.copyList.map((copy) => ({
        label: 'ISBN: ' + copy.isbn,
        value: copy._id,
      }));
    },
  },
  watch: {
    'Form.book'() {
      this.fetchCopies();
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['book', 'adjustment_type'].includes(key)) {
            acc[key] = { required };
          } else if (
            key == 'new_book_copy' &&
            this.Form.adjustment_type == 'add'
          ) {
            acc[key] = { required };
          } else if (
            ['book_copy', 'reason'].includes(key) &&
            this.Form.adjustment_type == 'remove'
          ) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    addCopy() {
      this.Form.new_book_copy = [
        ...this.Form.new_book_copy,
        { isbn: '', health: 0 },
      ];
    },
    removeCopy(index) {
      this.Form.new_book_copy.splice(index, 1);
      this.Form.new_book_copy = [...this.Form.new_book_copy];
      this.Form = { ...this.Form };
    },

    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      if (this.isAdd) this.handleAdd();
      else this.handleRemove();
      this.initial = true;
    },
    populateData() {
      Object.keys(this.oldForm).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });

      this.Form['status'] = ['closed', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;
    },
    async handleAdd() {
      this.working = true;
      let newForm = {
        book: this.Form.book,
        new_book_copy: this.Form.new_book_copy,
        description: this.Form.description,
      };

      let response = await this.request({
        method: 'post',
        url: 'school/adjustment/add',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Adjustment added successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },
    async handleRemove() {
      this.working = true;
      let newForm = {
        book: this.Form.book,
        book_copy: this.Form.book_copy,
        reason: this.Form.reason,
        description: this.Form.description,
      };
      let response = await this.request({
        method: 'post',
        url: 'school/adjustment/remove',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Adjustment added successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async fetchCopies() {
      let response = await this.request({
        method: 'get',
        url: 'school/bookCopy/active/' + this.Form.book,
      });
      if (response && !response.error) {
        this.copyList = response.record;
      } else {
        this.copyList = [];
      }
    },

    async fetchBooks() {
      this.loading = true;
      const url = `school/book/active`;
      let response = await this.request({
        method: 'get',
        url: url,
      });
      if (response && !response.error) {
        this.bookList = response.record;
      } else {
        this.bookList = [];
      }
      this.loading = false;
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    await this.fetchBooks();
  },
};
</script>

<style></style>
