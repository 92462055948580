<template>
  <div>
    <base-title title="Manage Communication Books">
      <template #actions>
        <div class="flex">
          <base-button
            icon="plus"
            label="Add New"
            class="px-3"
            @click="handleCreateNew"
          />
        </div>
      </template>
    </base-title>
    <div class="bg-white p-1 px-4 mt-2 rounded-default">
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :hasActions="true"
        :handlers="handlers"
        :loading="loading"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div>
            {{ getProp(item.student, header.key) }}
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';

export default {
  components: { DTable },
  data() {
    return {
      items: [],
      loading: false,
      headers: [
        { label: 'Name', key: 'profilePic' },
        { label: 'Group', key: 'schoolId' },
        { label: 'Status', key: 'status' },
      ],
      handlers: [
        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEdit,
          fullParam: true,
        },
        {
          label: 'Delete',
          icon: 'trash-alt',
          handler: this.deleteTemplate,
          fullParam: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,

    async fetchItems() {
      let response = await this.request({
        method: 'get',
        url: 'school/commbook/template',
      });
      if (response && !response.error) {
        console.log(response);
        this.items = response.record;
      }
    },
    handleEdit(id) {
      this.$router.push({ name: 'comm-update', params: { id } });
    },
    handleCreateNew() {
      this.$router.push({ name: 'comm-create' });
    },

    async deleteTemplate() {
      let componentProps = {
        message: 'Are you sure you want to delete this template',
        title: 'This action is not reversible.',
      };

      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDeleteTemplate,
      });
    },

    async proceedDeleteTemplate() {
      let response = await this.request({
        method: 'delete',
        url: `school/commbook/template/:${this.selectedBook}`,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: response.message,
          error: false,
        });
        this.fetchForm();
      }
    },
  },

  created() {
    this.fetchItems();
  },
};
</script>
