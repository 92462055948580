import moment from 'moment';
import Color from 'color';

export function truncateStr(str, maxLength = 100, ending = '...') {
  return str.length > maxLength
    ? str.substring(0, maxLength - ending.length) + ending
    : str;
}

export function partition(array, n) {
  const copy = array.slice();
  return copy.length ? [copy.splice(0, n)].concat(partition(copy, n)) : [];
}

export function isToday() {
  const today = moment.utc();
  const start = today.clone().startOf('day');
  const end = today.clone().endOf('day');

  return moment.isBetween(start, end, null, '[]');
}

export function ago(date, noSuffix) {
  return moment.utc(date).fromNow(noSuffix);
}

export function isBetween(target, from, to) {
  return moment.utc(target).isBetween(from, to, 'day', '[]'); //[] means inclusive
}
export function isSameOrBefore(date, target) {
  return moment.utc(date).isSameOrBefore(target, 'day');
}
export function isSameOrAfter(date, target) {
  return moment.utc(date).isSameOrAfter(target, 'day');
}

export function formatDate(date) {
  return moment(date).format('MMM DD, YYYY');
}

export function formatInputDate(date) {
  if (date) return moment(date).format('YYYY-MM-DD');
  else return undefined;
}

export function capitilize(word) {
  return word.charAt(0).toUpperCase() + word.substr(1);
}

export function getUserRole() {
  let user = localStorage.getItem('_u_k');
  user = user && JSON.parse(user);
  return user ? user.role : '';
}

export function getUserRoles() {
  let user = localStorage.getItem('_u_k');
  user = user && JSON.parse(user);
  return user ? user.roles : [];
}

export const tuneColor = {
  /* eslint-disable implicit-arrow-linebreak */
  darken: (colorValue, amount) => Color(colorValue).darken(amount).string(),
  lighten: (colorValue, amount) => Color(colorValue).lighten(amount).string(),
};

export const buttonVariants = {
  primary: '#ec3118',
  success: '#0B875B',
  danger: '#E13C3C',
  warning: '#F89C1C',
  info: '#0fb9b1',
  secondary: '#F4F5F7',
};

export function responseFields(attributes) {
  let attrs = '';
  if (attributes.length) {
    attributes.forEach((attr) => {
      attrs += `${attr.id},`;
    });
  }
  return attrs;
}

export function isValidEmail(value) {
  return /.+@.+\..+/.test(value);
}

export function isRequired(value) {
  return ['', null, undefined].indexOf(value) === -1;
}

export function isAlpha(value) {
  return /^[a-zA-Z][a-zßüÜöÖäÄ_][\wßüÜöÖäÄ]*$/g.test(value); // MARK: Alphabets
}

export function strongPassword(value) {
  return (
    /[a-z]/.test(value) && //MARK: checks for a-z
    /[0-9]/.test(value) && //MARK: checks for 0-9
    /\W|_/.test(value) && //MARK: checks for special char
    /[A-Z]/.test(value) && //MARK: checks for capital letterd
    value.length >= 8
  );
}

export function getErrorMessages(label, validationResult) {
  const MESSAGES = {
    required: `${label} is required`,
    email: `${label} is invalid email.`,
    sameAsPassword: `${label} did not match with Password.`,
    sameAsNewPassword: `${label} did not match with New Password.`,
    noFileSelected: 'Select a file please.',
    minValue: `${label} is less than the allowed value`,
    maxValue: `${label} is greater than the allowed value`,
    minLength: `Minimum password length is 8 characters`,
    maxLength: `Maximum password length is 32 characters`,
  };
  let valKeys = Object.keys(validationResult);
  let rules = Object.keys(MESSAGES).filter((key) => valKeys.includes(key));
  if (!validationResult.$invalid) return [];
  return rules.reduce((messageAcc, rule) => {
    if (!validationResult[rule]) messageAcc.push(MESSAGES[rule]);
    return messageAcc;
  }, []);
}

export function validate(meta, rootValidationResult) {
  if (!rootValidationResult.$invalid) return null;
  let errors = {};
  meta.forEach(({ key, label }) => {
    let keyArr = key.split('.');
    let keyStr = keyArr[keyArr.length - 1];
    if (!label) label = keyStr[0].toUpperCase() + keyStr.slice(1);
    errors[keyStr] = getErrorMessages(
      label,
      getPropByString(rootValidationResult, key)
    );
  });
  return errors;
}

export function validateArray(_label, meta, rootValidationResult) {
  if (!rootValidationResult[_label].$invalid) return null;
  return meta.map((item = [], index) => {
    return item.reduce((acc, { key, label }) => {
      let result = getPropByString(
        rootValidationResult,
        `${_label}.$each.${index}`
      );
      if (result && result[key]) {
        acc[key] = getErrorMessages(label, result[key]);
      }
      return acc;
    }, {});
  });
}

/// NOTE: brings the required field from the inside of a nested object.
export function getPropByString(obj, propString) {
  if (!obj || !propString) return undefined;
  const props = propString.split('.');
  let value = obj;
  for (let prop of props) {
    value = value?.[prop];
    if (value === undefined) {
      return undefined;
    }
  }

  return value ?? '';
}

export const isPlainObject = (obj) =>
  Object.prototype.toString.call(obj) === '[object Object]';

export const isUndefined = (value) => value === undefined;

export const isEmptyObj = (key) => Object.keys(key).length === 0;

export const isEmptyArr = (arr) => Array.isArray(arr) && arr.length === 0;

export const hasEmptykeys = (key) => Object.keys(key).find((k) => k == '');

export const isNull = (value) => value === null;

export const isArray = (value) => Array.isArray(value);

export const isDate = (value) => value instanceof Date && !isNaN(value);

export const canDate = (value) => {
  return moment(value, moment.ISO_8601, true).isValid();
};

export const keys = (obj) => Object.keys(obj);

export const stringifyQueryObj = (obj) => {
  if (!isPlainObject(obj)) {
    return '';
  }

  const query = keys(obj)
    .map((key) => {
      const value = obj[key];
      if (isUndefined(value)) {
        return '';
      } else if (isNull(value)) {
        return String(key);
      } else if (isArray(value)) {
        return value
          .reduce((results, value2) => {
            if (isNull(value2)) {
              results.push(String(key));
            } else if (!isUndefined(value2)) {
              // Faster than string interpolation
              results.push(String(key) + '=' + String(value2));
            }
            return results;
          }, [])
          .join('&');
      }
      // Faster than string interpolation
      return String(key) + '=' + String(value);
    })
    /* must check for length, as we only want to filter empty strings, not things that look falsey! */
    .filter((x) => x.length > 0)
    .join('&');

  return query ? `?${query}` : '';
};

export const toDDMMYYYY = (date) => moment(date).format('DD-MM-YYYY');
export const toYYYYMMDD = (date) => moment(date).format('yyyy-MM-DD');

export const timeToMinutes = (time) =>
  Number(time.split(':')[0]) * 60 + Number(time.split(':')[1]);

export const timeToSeconds = (time) =>
  Number(time.split(':')[0]) * 60 * 60 + Number(time.split(':')[1]) * 60;

export const getDateTime = (date) =>
  moment(date).format('ddd, DD-MM-YYYY HH:mm');
