<template>
  <div>
    <base-title title="Manage Books">
      <template #actions>
        <div class="flex justify-center items-center space-x-2">
          <base-select
            placeholder=""
            v-model="activeCategory"
            :items="CATEGORY_OPTIONS"
            searchable
          />
          <base-button
            label="New Book"
            icon="plus"
            class="px-2"
            code="gr-add"
            @click="handleCreateBook"
          />
        </div>
      </template>
    </base-title>

    <div class="bg-white p-1 px-4 mt-2 rounded-default overflow-x-auto">
      <div>
        <d-table
          #default="{ item }"
          :headers="headers"
          :items="items"
          :hasActions="true"
          :totalItems="totalItems"
          :handlers="handlers"
          :loading="loading"
          code="stu-view"
        >
          <td v-for="(header, i) in headers" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'cover'">
                <div class="flex space-x-5 items-center">
                  <div
                    class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                  >
                    <img
                      v-if="item.cover"
                      :src="IMAGES_PATH + item.cover"
                      v-viewer="{ inline: false, movable: true }"
                      class="w-full h-full object-cover rounded-full cursor-pointer"
                      alt=""
                      crossorigin="anonymous"
                    />
                    <icon
                      v-else
                      :size="18"
                      name="course"
                      class="w-full h-full"
                    ></icon>
                  </div>
                  <div>
                    <p
                      class="cursor-pointer hover:underline font-semibold text-textDarkest uppercase"
                      @click="handleDetail(item._id)"
                    >
                      {{ item.name }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-else-if="header.key === 'status'">
                <status-chip
                  :label="item['status']"
                  style="max-width: fit-content"
                />
              </div>

              <div v-else class="capitalize">
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import { IMAGES_PATH } from '@/config/config.js';

export default {
  components: { DTable },
  data() {
    return {
      IMAGES_PATH,
      activeCategory: '',
      categoryItems: [],
      totalItems: '',
      items: [],
      loading: false,
      headers: [
        { label: 'Name', key: 'cover' },
        { label: 'Author', key: 'author' },
        { label: 'Code', key: 'code' },
        { label: 'Total Copies', key: 'copies' },
        { label: 'Available', key: 'available' },
        { label: 'Status', key: 'status' },
      ],
      handlers: [
        {
          label: 'Edit',
          icon: 'pencil-alt',
          name: 'update',
          handler: this.handleEditItem,
          fullParam: true,
          filter: () => this.authCodes.includes('stu-edit'),
        },

        {
          label: 'Manage Copies',
          icon: 'course',
          name: 'manage copies',
          handler: this.handleCopyDetail,
          idParam: true,
          filter: () => this.authCodes.includes('stu-rm'),
        },

        {
          label: 'Delete',
          icon: 'trash-alt',
          name: 'delete',
          handler: this.handleDeleteItem,
          idParam: true,
          filter: () => this.authCodes.includes('stu-rm'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    ...mapGetters('main', ['error']),
    CATEGORY_OPTIONS() {
      return this.categoryItems.map((category) => ({
        label: category.name,
        value: category._id,
      }));
    },
  },
  watch: {
    activeCategory() {
      this.fetchBooks();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,

    handleCreateBook() {
      let componentProps = {
        book_category: this.activeCategory,
      };
      eventBus.$emit('open-modal', {
        modalId: 57,
        componentProps,
        cb: this.fetchBooks,
      });
    },

    async handleEditItem(item) {
      let componentProps = {
        oldForm: item,
      };
      eventBus.$emit('open-modal', {
        modalId: 57,
        componentProps,
        cb: this.fetchBooks,
      });
    },

    async handleDeleteItem(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the selected Shelf',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async fetchBooks() {
      this.loading = true;
      const url = `school/book/${this.activeCategory}`;
      let response = await this.request({
        method: 'get',
        url: url,
      });
      if (response && !response.error) {
        this.items = response.record;
        this.totalItems = response.total;
      } else {
        this.items = [];
        this.totalItems = 0;
      }
      this.loading = false;
    },

    async fetchCategories() {
      let response = await this.request({
        method: 'get',
        url: 'school/bookCategory/active',
      });
      if (response && !response.error) {
        this.categoryItems = response.record;
        this.activeCategory = response.record[0]._id;
      } else {
        this.categoryItems = [];
        this.activeCategory = '';
      }
    },

    handleCopyDetail(id) {
      console.log('id: ', id);
      this.$router.push({ name: 'book-copy', params: { id } });
    },

    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/book/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Book deleted successfully',
        });
        this.fetchBooks();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },

  async created() {
    this.working = true;
    await this.fetchCategories();
    this.working = false;
  },
};
</script>

<style></style>
