import Dashboard from '@/views/protected/dashboard/Dashboard.vue';
import ProtectedEntry from '@/views/entry/ProtectedEntry.vue';
import SimpleEntry from '@/views/entry/SimpleEntry.vue';
import USER_MANAGEMENT from './children/user_management.js';
import ENROLLMENT from './children/enrollment.js';
import ROLE_MANAGEMENT from './children/role_management.js';
import SCHOOL_MANAGEMENT from './children/school_management.js';
import COMMON_ROUTES from './children/common';
import SCHOOL_STRUCTURE_MANAGEMENT from './children/school_structure_management.js';
import LIBRARY_MANAGEMENT from './children/library_management.js';
import DIGITAL_LIBRARY from './children/digital_library.js';
import DIGITAL_LAB from './children/digital_lab.js';
import SCHEDULE_ROUTES from './children/schedule.js';
import ATTENDANCE_ROUTES from './children/attendance_management.js';
import ChattingPage from '@/views/protected/chat/ChatPage.vue';
import GRADING_MANAGEMENT from './children/grading_management.js';
import SCHOOL_FEE_MANAGEMENT from './children/school_fee_management.js';
import MY_SCHOOL from './children/my_school.js';
import ACADEMIC from './children/academics.js';
import ProfileSetting from '@/views/protected/setting/Setting.vue';
import SearchEngine from '../views/protected/common/SearchEngine.vue';
import StudentDetail from '../views/protected/user_management/student_management/Detail.vue';
import ParentDetail from '@/views/protected/user_management/parent_management/Detail.vue';
import TeacherDetail from '@/views/protected/user_management/teacher_management/Detail.vue';
import SchoolOfficialDetail from '@/views/protected/user_management/school_official_management/Detail.vue';

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    slug: 'db',
    component: SimpleEntry,
    props: {
      childComponent: Dashboard,
    },
  },
  {
    path: '/school',
    name: 'school-management',
    slug: 'sh',
    component: ProtectedEntry,
    redirect: { name: 'enrollment-type-list' },
    children: [...SCHOOL_MANAGEMENT, ...SCHOOL_STRUCTURE_MANAGEMENT],
  },
  {
    path: '/registrations',
    name: 'user-management',
    slug: 'ur',
    component: ProtectedEntry,
    redirect: { name: 'manage-student' },
    children: [...USER_MANAGEMENT],
  },
  {
    path: '/academics',
    name: 'user-management',
    slug: 'ur',
    component: ProtectedEntry,
    redirect: { name: 'grade_verification' },
    children: [...ACADEMIC],
  },
  {
    path: '/enrollment',
    name: 'enrollment',
    slug: 'en',
    component: ProtectedEntry,
    redirect: { name: 'academic-section' },
    children: [...ENROLLMENT],
  },
  {
    path: '/schedule',
    name: '',
    slug: 'sd',
    component: ProtectedEntry,
    redirect: { name: 'school-yearly-schedule' },
    children: [...SCHEDULE_ROUTES],
  },
  {
    path: '/attendance',
    name: 'attendance',
    slug: 'att',
    component: ProtectedEntry,
    redirect: { name: 'attendance-dashboard' },
    children: [...ATTENDANCE_ROUTES],
  },
  // {
  //   path: '/roles',
  //   name: 'roles',
  //   slug: 'rl',
  //   component: ProtectedEntry,
  //   redirect: { name: 'roles' },
  //   children: [...ROLE_MANAGEMENT],
  // },
  {
    path: '/fee',
    name: '',
    slug: '',
    component: ProtectedEntry,
    redirect: { name: 'payment-types' },
    children: [...SCHOOL_FEE_MANAGEMENT],
  },
  {
    path: '/search',
    name: 'search',
    slug: 'db',
    component: SimpleEntry,
    props: {
      childComponent: SearchEngine,
    },
  },
  {
    path: '/search/student/detail/:id',
    name: 'studentSearch',
    slug: 'db',
    component: SimpleEntry,
    props: {
      childComponent: StudentDetail,
    },
  },
  {
    path: '/search/parent/detail/:id',
    name: 'parentSearch',
    slug: 'db',
    component: SimpleEntry,
    props: {
      childComponent: ParentDetail,
    },
  },
  {
    path: '/search/teacher/detail/:id',
    name: 'teacherSearch',
    slug: 'db',
    component: SimpleEntry,
    props: {
      childComponent: TeacherDetail,
    },
  },
  {
    path: '/search/official/detail/:id',
    name: 'officialSearch',
    slug: 'db',
    component: SimpleEntry,
    props: {
      childComponent: SchoolOfficialDetail,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    slug: 'sh',
    component: ProtectedEntry,
    redirect: { name: 'school-pref' },
    children: [...MY_SCHOOL, ...ROLE_MANAGEMENT],
    // props: {
    //   childComponent: MySchool,
    // },
    // redirect: { name: 'settings' },
    // children: [
    //   {
    //     label: 'my-school',
    //     path: 'my-school',
    //     name: 'my-school',
    //     component: MySchool,
    //     props: true,
    //   },
    // ],
  },
  {
    path: '/setting',
    name: 'setting',
    slug: 'db',
    component: SimpleEntry,
    props: {
      childComponent: ProfileSetting,
    },
  },

  {
    path: '/library',
    name: 'library-management',
    slug: '',
    component: ProtectedEntry,
    redirect: { name: 'shelf' },
    children: [...LIBRARY_MANAGEMENT],
  },
  {
    path: '/digital-library',
    name: 'digital-library',
    slug: '',
    component: ProtectedEntry,
    redirect: { name: 'library-overview' },
    children: [...DIGITAL_LIBRARY],
  },
  {
    path: '/digital-lab',
    name: 'lab-management',
    slug: '',
    component: ProtectedEntry,
    redirect: { name: 'lab-overview' },
    children: [...DIGITAL_LAB],
  },
  {
    path: '',
    name: 'profile-setting',
    slug: '',
    component: ProtectedEntry,
    children: [...COMMON_ROUTES],
  },
  {
    path: '/chat',
    name: 'chat',
    slug: '',
    component: ChattingPage,
  },
  {
    path: '/grading',
    name: '',
    slug: '',
    component: ProtectedEntry,
    redirect: { name: 'grading-overview' },
    children: [...GRADING_MANAGEMENT],
  },
];
