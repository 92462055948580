<template>
  <div class="bg-white p-6">
    <div
      class="flex flex-col items-center justify-center text-primary uppercase font-semibold"
    >
      <img src="@/assets/img/2953962.jpg" alt="" class="w-64" />
      <h3 :class="nodataClass">{{ message }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String },
    nodataClass: { type: String },
  },
};
</script>
