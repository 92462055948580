<template>
  <div
    class="bg-white p-5 py-2 items-center rounded-default"
    :class="customClass"
  >
    <p class="text-textDark font-medium text-lg col-span-1">{{ title }}</p>
    <slot name="infos"></slot>

    <div>
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: 'title' },
    customClass: { type: String, default: 'flex justify-between' },
  },
};
</script>

<style></style>
