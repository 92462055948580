export const navs = [
  {
    label: 'Dashboard',
    name: 'dashboard',
    to: 'dashboard',
    slug: 'db',
    code: 'da',
    icon: 'th',

    allowedFor: ['super_admin', 'registrar'],
  },
  {
    label: 'Setup',
    name: 'school',
    to: 'school',
    slug: 'sch',
    code: 'sch',
    icon: 'sitemap',

    allowedFor: ['super_admin', 'registrar'],
  },
  {
    label: 'Admission',
    name: 'user',
    to: 'registrations',
    slug: 'ur',
    code: 'adm',
    icon: 'user-plus',

    allowedFor: ['super_admin', 'registrar'],
  },
  {
    label: 'Enrollment',
    name: 'enrollment',
    to: 'enrollment',
    code: 'enrl',
    slug: 'en',
    icon: 'signature',

    allowedFor: ['super_admin', 'registrar'],
  },
  {
    label: 'Academics',
    name: 'Academics',
    slug: 'acds',
    to: 'academics',
    code: 'adm',
    icon: 'user-graduate',
  },
  {
    label: 'Plan',
    name: 'schedule',
    slug: 'scd',
    code: 'tmt',
    to: 'schedule',
    icon: 'calendar',

    allowedFor: ['super_admin', 'registrar'],
  },
  // { label: 'Grading', name: "grading", slug: "gr", allowedFor: ['super_admin', 'registrar'] },
  {
    label: 'Attendance',
    name: 'attendance',
    slug: 'att',
    code: 'att',
    to: 'attendance',
    icon: 'attendance-1',

    allowedFor: ['super_admin', 'registrar'],
  },
  {
    label: 'Library',
    name: 'library',
    slug: 'li',
    code: 'att',
    to: 'library',
    allowedFor: ['super_admin', 'registrar'],
    icon: 'attendance-1',
  },
  // {
  //   label: 'Library',
  //   name: 'library',
  //   slug: 'li',
  //   allowedFor: ['super_admin', 'registrar'],
  // },
  // {
  //   label: 'Lab',
  //   name: 'lab',
  //   slug: 'la',
  //   allowedFor: ['super_admin', 'registrar'],
  // },
  {
    label: 'Fee',
    name: 'fee',
    to: 'fee',
    slug: 'fee',
    code: 'fee',
    icon: 'dollar-sign',

    allowedFor: ['super_admin', 'registrar'],
  },
  // {
  //   label: 'SMS',
  //   name: 'sms',
  //   slug: 'fee',
  //   allowedFor: ['super_admin', 'registrar'],
  // },
  {
    label: 'Search',
    name: 'search',
    to: 'search',
    slug: 'search',
    code: 'ser',
    icon: 'search',

    allowedFor: ['super_admin', 'registrar', 'school_official'],
  },
  // "Shl-Management"
];

export const eventColors = [
  // { primary: '#ffffff', secondary: '#e3dbd7' },
  // { primary: '#d9dadf', secondary: '#a7abb7' },
  // { primary: '#445760', secondary: '#2b373d' },
  // { primary: '#ffffff', secondary: '#fcdbc9' },
  // { primary: '#ffffff', secondary: '#ffde5d' },
  // { primary: '#ffffff', secondary: '#cbe9bf' },
  // { primary: '#ffffff', secondary: '#b6e8f3' },
  // { primary: '#ffffff', secondary: '#e9d4ff' },
  // { primary: '#ffffff', secondary: '#f6d5e5' },
  // { primary: '#f5e1da', secondary: '#e3ab9a' },
  // { primary: '#ffdfb7', secondary: '#ffab40' },
  // { primary: '#c4e1c5', secondary: '#78bb7b' },
  // { primary: '#b7e1e4', secondary: '#5ab9c1' },
  // { primary: '#cbdcf7', secondary: '#84adea' },
  // { primary: '#ffe0e6', secondary: '#ffaebd' },
  // { primary: '#a0144f', secondary: '#660d33' },
  // { primary: '#a71c1c', secondary: '#6d1212' },
  // { primary: '#275d2b', secondary: '#18391a' },
  // { primary: '#045b62', secondary: '#02393e' },
  // { primary: '#14539a', secondary: '#0d3562' },
  // { primary: '#6d41a1', secondary: '#462a68' },
  { secondary: '#ffffff', primary: '#e3dbd7' },
  { secondary: '#ffffff', primary: '#a7abb7' },
  { secondary: '#ffffff', primary: '#2b373d' },
  { secondary: '#ffffff', primary: '#fcdbc9' },
  { secondary: '#ffffff', primary: '#ffde5d' },
  { secondary: '#ffffff', primary: '#cbe9bf' },
  { secondary: '#ffffff', primary: '#b6e8f3' },
  { secondary: '#ffffff', primary: '#e9d4ff' },
  { secondary: '#ffffff', primary: '#f6d5e5' },
  { secondary: '#ffffff', primary: '#e3ab9a' },
  { secondary: '#ffffff', primary: '#ffab40' },
  { secondary: '#ffffff', primary: '#78bb7b' },
  { secondary: '#ffffff', primary: '#5ab9c1' },
  { secondary: '#ffffff', primary: '#84adea' },
  { secondary: '#ffffff', primary: '#ffaebd' },
  { secondary: '#ffffff', primary: '#6d1212' },
  { secondary: '#ffffff', primary: '#18391a' },
  { secondary: '#ffffff', primary: '#02393e' },
  { secondary: '#ffffff', primary: '#0d3562' },
  { secondary: '#ffffff', primary: '#462a68' },
];

export const events = [
  {
    label: 'Exhibition',
    value: 'exhibition',
    icon: 'clock',
    color: '#ffaebd',
  },
  {
    label: 'Exam',
    value: 'exam',
    icon: 'folder-solid',
    color: '#e9d4ff',
  },
  {
    label: 'Metting',
    value: 'meeting',
    icon: 'user',
    color: '#ffaebd',
  },
];

export const sessionCategories = [
  { label: 'Class', value: 'class' },
  { label: 'Lab', value: 'lab' },
  { label: 'Field', value: 'field' },
  { label: 'Break', value: 'break' },
  { label: 'Lunch', value: 'lunch' },
];

export const sessionThemes = {
  class: {
    color: '#ffffff',
    text: '#333',
    icon: 'chalkboard-user',
  },
  break: {
    color: '#88A9C3',
    text: 'white',
    icon: 'shield',
  },
  lunch: {
    color: '#2B4257',
    text: 'white',
    icon: 'school',
  },
  lab: {
    color: '#614E41',
    text: 'white',
    icon: 'atom',
  },
  field: {
    color: '#fef08a',
    text: '#333',
    icon: 'eye',
  },
};

export const static_roles = [
  'super_admin',
  'registrar',
  'course_author',
  'teacher',
  'student',
  'parent',
];
