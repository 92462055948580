<template>
  <div
    class="lg:grid lg:grid-cols-3 flex space-x-0 h-full shadow-default pb-20"
  >
    <div
      class="flex-grow space-y-6 lg:col-span-3 border-r-4 border-grayBg px-2"
    >
      <!-- <calendar-config :id="id" class="px-4 py-3" /> -->
      <hr class="text-dgray" />
      <schedule-config :id="id" class="px-4 py-3" />
      <hr class="text-dgray" />
      <idcard-config :id="id" class="px-4 py-3" />
      <hr class="text-dgray" />
      <!-- <communication-book-config :id="id" class="px-4 py-3" /> -->
      <!-- <ApprovalConfig :id="id" class="px-4 py-3" /> -->
    </div>
    <div>
      <!-- <ConfigID /> -->
    </div>
  </div>
</template>

<script>
import ScheduleConfig from './components/ScheduleConfig.vue';
// import CalendarConfig from './components/CalendarConfig.vue';
import IdcardConfig from './components/IdcardConfig.vue';
// import CommunicationBookConfig from './components/CommunicationBookConfig.vue';
// import ConfigID from './components/ConfigID.vue';
// import ApprovalConfig from './components/ApprovalConfig.vue';

export default {
  props: ['id'],
  components: {
    ScheduleConfig,
    // CalendarConfig,
    IdcardConfig,
    // CommunicationBookConfig,
    // ConfigID,
    // ApprovalConfig,
  },
};
</script>

<style></style>
