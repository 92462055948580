<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title> Return Book </template>
    <template>
      <div class=" ">
        <!-- <div class="flex justify-center space-x-2 items-center">
            <icon name="course" class="text-secondary" />
            <p class="text-lg text-center capitalize">{{ item.name }}</p>
          </div> -->
        <div class="grid grid-cols-2 gap-3 my-2">
          <div
            class="border-1 col-span-1 p-4 rounded-default border-gray-100 shadow-sm"
          >
            <span class="font-bold">USER INFORMATION</span>
            <!-- SELECT USER INFO -->
            <!-- USER -->
            <div class="py-7 flex justify-center">
              <div
                class="w-32 h-32 rounded-full bg-gray-100 flex justify-center items-center"
              >
                <img
                  v-if="item.user.profilePic"
                  :src="PROFILE_PATH + item.user.profilePic"
                  v-viewer="{ inline: false, movable: true }"
                  class="w-full h-full object-cover rounded-full"
                  alt=""
                  crossorigin="anonymous"
                />
                <icon
                  v-else
                  :responsive="true"
                  name="user"
                  class="w-full h-full"
                ></icon>
              </div>
            </div>
            <div class="py-2 text-center">
              <div class="text-xl capitalize font-semibold">
                {{ item.user.fullName }}
              </div>
              <div class="font-sans">{{ item.user.userId }}</div>

              <div v-if="item.user['phoneNumber']" class="font-sans">
                {{ item.user['phoneNumber'].join(' | ') }}
              </div>
            </div>
          </div>

          <div
            class="col-span-1 border-1 p-4 rounded-default border-gray-100 shadow-sm"
          >
            <span class="font-bold">BOOK INFO</span>
            <div class="grid grid-cols-1 gap-2 my-2">
              <div class="flex space-x-2 items-center mx-4">
                <icon name="course" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.book.name }}
                </p>
              </div>
              <div class="flex space-x-2 items-center mx-4">
                <icon name="user" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.book.author }}
                </p>
              </div>

              <div class="flex space-x-2 items-center mx-4">
                <icon name="address-book" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.book_copy.isbn }}
                </p>
              </div>

              <div class="flex space-x-2 items-center mx-4">
                <icon
                  name="plus-square"
                  :class="{
                    'text-success': Form.health === 'new',
                    'text-error': Form.health === 'old',
                    'text-orange': Form.health === 'moderate',
                  }"
                />
                <p
                  class="text-lg font-semibold text-success text-center capitalize"
                  :class="{
                    'text-success': Form.health === 'new',
                    'text-error': Form.health === 'old',
                    'text-orange': Form.health === 'moderate',
                  }"
                >
                  {{ Form.health }}
                </p>
              </div>

              <!-- IF AVAILABLE -->

              <base-select
                label="Returned Health"
                :items="HEALTH_OPTIONS"
                placeholder="health"
                v-model="Form.health"
                :errors="errors.health"
                bordered
              />

              <base-input
                label="Returned Description"
                placeholder="description"
                v-model="Form.description"
                :errors="errors.description"
              />

              <!-- IF NOT AVAILABLE -->
            </div>
            <br />
          </div>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate },
  props: ['item', 'isReturn'],
  mixins: [validation],
  data() {
    return {
      PROFILE_PATH,
      Form: {
        health: this.item.issued_health,
        description: '',
      },
      errors: {},
      working: false,
      check: false,
      meta: [{ key: 'Form.health', label: 'Returned Health' }],
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    STEPS() {
      return ['Select User', 'Issue Information'];
    },
    HEALTH_OPTIONS() {
      return [
        { label: 'New', value: 'new' },
        { label: 'Moderate', value: 'moderate' },
        { label: 'Old', value: 'old' },
      ];
    },

    USER_ICON() {
      return {
        student: 'user-graduate',
        teacher: 'chalkboard-user',
        school_official: 'school',
      };
    },
  },

  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['health'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),

    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.initial = false;
      this.check = true;
      if (!this.isValid())
        return eventBus.$emit('open-toast', {
          message: 'please check all the fields',
          error: true,
        });
      if (this.isReturn) this.handleReturn();
      else this.handleNotReturn();
      this.initial = true;
    },

    async handleReturn() {
      this.working = true;

      let response = await this.request({
        method: 'put',
        url: 'school/issue/return/' + this.item._id,
        data: this.Form,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Book Returned successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },
    async handleNotReturn() {
      this.working = true;

      let response = await this.request({
        method: 'post',
        url: 'school/issue/not-return/' + this.item._id,
        data: this.Form,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: response.message,
          warning: response.warning,
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
  },
};
</script>

<style></style>
