<template>
  <div>
    <base-title :customClass="'grid grid-cols-3'" :title="'Issue Books'">
      <template #actions>
        <div class="flex space-x-3 col-span-2">
          <div class="flex items-center">
            <input
              type="text"
              v-model="keyword"
              @input="debounceSearch"
              class="bg-gray-50 border-1 border-gray-200 focus:bg-white rounded-full py-1 w-full px-4 outline-none"
              placeholder="Search..."
            />
            <div class="cursor-pointer -ml-6">
              <icon
                name="search"
                class="text-textDark transform rotate-90"
                :size="12"
              ></icon>
            </div>
          </div>
        </div>
      </template>
    </base-title>
    <div class="p-1 px-4 mt-2">
      <loader v-if="working" type="list" :amount="18"></loader>
      <div v-else-if="items.length" class="grid grid-cols-5 gap-5">
        <book-card
          v-for="(item, i) in items"
          :key="i"
          :item="item"
          @click="handleIssueBook(item)"
        />
      </div>
      <base-no-data v-else message="No Books Available" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import eventBus from '@/eventBus';
import { stringifyQueryObj } from '@/utils';
import BookCard from '@/views/protected/library_management/issue/components/BookCard.vue';
export default {
  components: {
    BookCard,
  },
  data() {
    return {
      keyword: '',
      items: [],
      working: false,
      categoryItems: [],
      activeCategory: '',
      debounce: null,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    CATEGORY_OPTIONS() {
      return this.categoryItems.map((category) => ({
        label: category.name,
        value: category._id,
      }));
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    debounceSearch() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.searchBook();
      }, 500);
    },

    async handleIssueBook(item) {
      let componentProps = {
        item,
      };
      eventBus.$emit('open-modal', {
        modalId: 59,
        componentProps,
        cb: this.fetchActiveBooks,
      });
    },

    async fetchActiveBooks() {
      let response = await this.request({
        method: 'get',
        url: 'school/book/active',
      });
      console.log('response: ', response);
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
    },

    async searchBook() {
      this.working = true;
      let string_query = stringifyQueryObj({
        keyword: this.keyword,
      });

      let url = 'school/book/search-active';

      url += string_query;
      let response = await this.request({
        method: 'get',
        url,
      });
      if (response && !response.error) {
        this.items = response.items;
        console.log('this.items: ', this.items);
      } else {
        this.items = [];
      }
      this.working = false;
    },
    async fetchCategories() {
      let response = await this.request({
        method: 'get',
        url: 'school/bookCategory/active',
      });
      if (response && !response.error) {
        this.categoryItems = response.record;
        this.activeCategory = response.record[0]._id;
      } else {
        this.categoryItems = [];
        this.activeCategory = '';
      }
    },
  },
  async created() {
    await this.fetchCategories();
    await this.fetchActiveBooks();
  },
};
</script>

<style></style>
