import apiService from './apiService';
import { handleError } from './handleErrorService';

export default {
  async request(params) {
    const requestData = {
      url: params.url,
      method: params.method,
      data: params.data,
    };

    try {
      const data = await apiService.customRequest(requestData);
      return data;
    } catch (error) {
      if (error.response && error.response.status == 422)
        return {
          error: true,
          code: 422,
          joiError: true,
          joi: error.response.data,
        };
      return handleError(error);
    }
  },
  async getApplication(params) {
    const requestData = {
      url: params.url,
      method: params.method,
      data: params.data,
      responseType: params.responseType,
    };

    try {
      const data = await apiService.customRequest(requestData);
      return data;
    } catch (error) {
      return handleError(error);
    }
  },
  async upload(params) {
    const requestData = {
      method: params.method,
      headers: { 'Content-Type': 'multipart/form-data' },
      url: params.url,
      data: params.data,
    };

    try {
      const { data } = await apiService.customRequest(requestData);
      return data;
    } catch (error) {
      return handleError(error);
    }
  },
};
