<template>
  <div class="w-full" :style="{ width: 'auto' }">
    <label style="font-size: 16px" class="text-textDarkest" v-if="label">
      {{ label }}<span v-if="required && !success" class="text-error"> *</span>
    </label>
    <div class="text-textDark relative">
      <div
        :style="'font-size: ' + 16 + 'px;'"
        class="flex justify-between items-center border-1 border-dgray cursor-pointer leading-tight bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 min-w-24"
        :class="{
          'bg-white': success && !notBordered,
          'bg-secondary text-white': emphasis,
          'bg-danger': !success && check && notBordered,
          'border-error bg-red-50 focus:border-rose focus:ring-1 focus:ring-rose focus:outline-none':
            !success && check && !notBordered,
          'border-textLight': success && notBordered,
          'py-2 px-3': padding === 'big',
          'py-1 px-2': padding === 'small',
          'rounded-default': !notRounded,
          'border-none': notBordered,
          'w-48': w48,
          'min-w-full': minWFull,
          [customClasses]: padding === 'custom',
        }"
        @click="handleOpen"
        v-outsideClick="{ handler: handleBlur }"
      >
        <div v-if="!itemSelected">Select</div>
        <div class="w-full" v-else>
          <slot :item="itemSelected">
            <div
              class="w-full inline-flex justify-between space-x-2 items-center flex-shrink-0"
            >
              <div class="flex flex-grow space-x-4">
                <div v-if="itemSelected.icon_name">
                  <icon
                    :size="20"
                    :name="itemSelected.icon_name"
                    class="w-full h-full"
                  ></icon>
                </div>
                <div>
                  <p>{{ itemSelected[searchKey] }}</p>
                </div>
              </div>
              <icon
                v-if="clearable"
                @click="clearValue"
                name="times"
                class="text-textLight"
              />
            </div>
          </slot>
        </div>
        <icon v-if="selectorOpened" name="caret-up" />
        <icon v-else name="caret-down" />
      </div>

      <div
        v-if="selectorOpened"
        class="selector bg-white absolute z-1000 w-full shadow-2xl rounded-lg p-1"
      >
        <input
          v-if="searchable"
          type="text"
          class="outline-none w-full p-2 bg-gray-50 rounded-lg"
          placeholder="Search..."
          autofocus
          v-model="search"
          @click.stop
          v-bind="$attrs"
        />
        <div v-if="itemsFiltered.length === 0" class="p-2">No data</div>
        <div v-else class="overflow-y-auto py-2" style="max-height: 40vh">
          <div
            class="hover:bg-gray-100 cursor-pointer rounded-lg"
            :class="{
              'p-2': padding === 'big',
              'p-1': padding === 'small',
            }"
            v-for="item in itemsFiltered"
            :key="item.value"
            @click="handleChange(item.value)"
          >
            <div class="flex space-x-4">
              <div v-if="item.icon_name">
                <icon
                  :size="20"
                  :name="item.icon_name"
                  class="w-full h-full"
                ></icon>
              </div>
              <div>
                <slot name="option" :item="item">{{ item[searchKey] }}</slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!success && check" class="text-xs" style="color: #ec3118">
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { stringifyQueryObj } from '@/utils';
import { mapActions } from 'vuex';

export default {
  inheritAttrs: false,
  props: {
    value: {},
    label: { type: String },
    size: { type: Number, default: 16 },
    errors: { type: Array, default: () => [] },
    padding: { type: String, default: ['big', 'small', 'custom'][0] },
    notBordered: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    searchKey: { type: String, default: 'label' },
    customClasses: { type: String, default: '' },
    check: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    notRounded: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    emphasis: { type: Boolean, default: false },
    w48: { type: Boolean, default: false },
    minWFull: { type: Boolean, default: false },
    icon_name: { type: String, default: '' },
    url: { type: String, default: '' },
    label_keys: { type: Array, default: () => [] },
    value_key: { type: String, default: 'value' },
  },
  data() {
    return {
      selectorOpened: false,
      search: '',
      users: [],
      timeout: null,
      activeItem: '',
    };
  },
  watch: {
    search(newSearch) {
      if (newSearch) this.debounceSearch(newSearch);
    },
    url() {
      this.users = [];
    },
  },
  computed: {
    success() {
      return this.errors.length === 0;
    },
    items() {
      return this.users.map((user) => ({
        label: this.label_keys.map((key) => user[key]).join(' - '),
        value: user._id,
      }));
    },

    detail() {
      return this.users.find((user) => user._id == this.activeItem);
    },

    itemsFiltered() {
      return this.items.filter((item) =>
        RegExp('.*' + this.search + '.*', 'i').test(item[this.searchKey])
      );
    },
    itemSelected() {
      return this.items.find((item) => item.value === this.value);
    },
  },

  methods: {
    ...mapActions('main', ['request']),

    handleFocus() {
      this.selectorOpened = true;
    },
    handleBlur() {
      this.selectorOpened = false;
      this.search = '';
    },
    clearValue() {
      this.$emit('input', null);
    },
    handleOpen() {
      if (!this.selectorOpened) this.handleFocus();
      else this.handleBlur();
    },
    handleChange(value) {
      this.activeItem = value;
      this.$emit('input', value);
      this.$emit('detail', this.detail);
    },
    debounceSearch(value) {
      // Clear the previous timeout if the user is still typing
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      // Set a new timeout to trigger the search function after 300ms
      this.timeout = setTimeout(() => {
        this.searchItem(value);
      }, 300); // Adjust the delay as needed
    },

    async searchItem(value) {
      this.working = true;
      let query_obj = {
        limit: 15,
        keyword: value,
      };
      let string_query = stringifyQueryObj(query_obj);

      let response = await this.request({
        method: 'get',
        url: this.url + string_query,
      });
      this.working = false;
      if (response && !response.error) {
        this.users = response.items;
      } else this.users = [];
    },
  },
  async created() {
    await this.searchItem(this.search);
  },
};
</script>

<style>
.selector {
  animation: slideUp 0.2s;
}
@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
.z-1000 {
  z-index: 3000;
}
</style>
