var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full bg-primary overflow-y-auto px-2 py-5 transform duration-500 print:hidden shadow-xl flex flex-col justify-between",class:{ ' w-12': _vm.collapsed, 'w-64': !_vm.collapsed }},[_c('div',{},[_c('div',{staticClass:"flex items-center justify-end mb-2"},[_c('div',{staticClass:"bg-gray-100 p-2 rounded-full cursor-pointer",on:{"click":_vm.handleToggleCollapse}},[_c('icon',{staticClass:"text-textDarkest",attrs:{"name":"bars"}})],1)]),(_vm.activeNavs)?_c('div',_vm._l((_vm.activeNavs),function(nav,index){return _c('div',{key:index,staticClass:"py-2 relative cursor-pointer hover:bg-gray-200 hover:text-textDarkest rounded-lg my-1",class:_vm.activePath == nav.path
            ? 'text-textDarkest bg-gray-100'
            : 'text-white',on:{"click":function($event){return _vm.handleNavigation(nav.path)}}},[(_vm.activePath == nav.path && !_vm.collapsed)?_c('div',{staticClass:"absolute left-0 top-0 bottom-0 flex items-center"},[_c('div',{staticClass:"w-1 h-3 rounded-sm bg-primary"})]):_vm._e(),(!_vm.collapsed)?_c('div',{staticClass:"flex items-center space-x-4 pl-8"},[_c('icon',{attrs:{"name":nav.icon}}),_c('div',[_vm._v(_vm._s(nav.label))])],1):_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            arrow: false,
            placement: 'right',
            animation: 'scale',
            theme: 'black',
          }),expression:"{\n            arrow: false,\n            placement: 'right',\n            animation: 'scale',\n            theme: 'black',\n          }"}],staticClass:"flex items-center justify-center py-0 mx-1",attrs:{"content":nav.label}},[_c('icon',{attrs:{"name":nav.icon}})],1)])}),0):_vm._e()]),_c('div',{staticClass:"grid bg-white bg-opacity-25 p-1 rounded-lg items-center cursor-pointer border-1 border-white border-opacity-50 hover:bg-opacity-50 duration-300",class:_vm.collapsed ? 'grid-cols-1' : 'grid-cols-8',on:{"click":_vm.showSchoolDetails}},[_c('div',{staticClass:"col-span-2 flex justify-center items-center"},[(_vm.school.logo && _vm.school.logo != 'undefined')?_c('div',{staticClass:"w-10 bg-gray-100 rounded-full overflow-hidden",class:_vm.collapsed ? 'h-6' : ' h-10'},[_c('img',{staticClass:"object-cover w-full",attrs:{"src":_vm.LOGO_PATH + _vm.school.logo,"alt":""}})]):_c('div',{staticClass:"bg-gray-100 rounded-full flex items-center justify-center",class:_vm.collapsed ? 'p-1' : 'p-3'},[_c('icon',{staticClass:"text-textDarkest",attrs:{"name":"school"}})],1)]),(!_vm.collapsed)?_c('p',{staticClass:"capitalize col-span-6 text-white"},[_vm._v(" "+_vm._s(_vm.school.name)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }