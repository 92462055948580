<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title> {{ isUpdate ? 'Update' : 'Create New' }} Book </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-2 gap-5">
          <div
            class="border-1 p-2 col-span-1 rounded-default border-gray-100 shadow-sm"
          >
            <span class="font-bold">UPLOAD COVER </span>
            <div
              class="w-40 h-32 my-4 cursor-pointer mx-auto"
              @click="handleOpenDialog('cover-input')"
            >
              <img
                v-if="Form.cover"
                :src="coverObj ? coverObj : IMAGES_PATH + Form.cover"
                alt=""
                class="object-cover w-full h-full rounded-lg"
                crossorigin="anonymous"
              />
              <icon
                v-else
                :responsive="true"
                name="course"
                class="w-full h-full"
              ></icon>
              <div class="mt-4">
                <input
                  class=""
                  style="display: none"
                  ref="cover-input"
                  @change="handleSelectFile('cover-input', 'Form.cover')"
                  type="file"
                  name="file-input"
                />
              </div>
            </div>
          </div>
          <div>
            <base-input
              label="Book Name"
              placeholder="Book name"
              v-model="Form.name"
              :errors="errors.name"
              capitalize
              required
              check
            />
            <base-input
              label="Author Name"
              placeholder="Author name"
              v-model="Form.author"
              :errors="errors.author"
              capitalize
              required
              check
            />
            <base-input
              label="Book Code"
              placeholder="book code"
              v-model="Form.code"
              :errors="errors.code"
              required
              check
              caps
            />
          </div>

          <base-text-area
            label="Description"
            placeholder="description"
            v-model="Form.description"
            :errors="errors.description"
            :rows="1"
          />

          <div class="col-span-1">
            <span>Tags </span>
            <base-multitype
              placeholder="tags"
              v-model="Form.tags"
              :items="Form.tags"
              :errors="errors.tags"
              :check="check"
            />
          </div>

          <div class="mt-1 col-span-2" v-show="!isUpdate">
            <div class="pb-2">Copies</div>
            <div class="space-y-3">
              <div class="grid grid-cols-12 items-center gap-1">
                <div class="col-span-6">ISBN</div>
                <div class="col-span-4">Health %</div>
              </div>
              <div
                v-for="(item, i) in Form.book_copy"
                :key="i"
                class="grid grid-cols-12 items-center gap-1"
              >
                <base-input
                  placeholder="isbn"
                  type="text"
                  class="col-span-6"
                  v-model="Form.book_copy[i].isbn"
                  :errors="errors.isbn"
                  caps
                />

                <base-select
                  bordered
                  :items="HEALTH_OPTIONS"
                  placeholder="health"
                  class="col-span-4"
                  v-model="Form.book_copy[i].health"
                  :errors="errors.health"
                />
                <div
                  class="cursor-pointer text-primary bg-gray-200 hover:bg-opacity-50 p-2 rounded-lg"
                  @click="addCopy(i)"
                >
                  <icon name="plus" :size="14" class="" />
                </div>

                <div
                  v-if="i > 0"
                  class="cursor-pointer text-error bg-gray-200 hover:bg-opacity-50 p-2 rounded-lg"
                  @click="removeCopy(i)"
                >
                  <icon name="trash-alt" :size="12" class="" />
                </div>
              </div>
            </div>
          </div>

          <switch-button label="Can Not Be Issued" v-model="Form.no_issue" />

          <switch-button label="Is Available" v-model="Form.status" />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import SwitchButton from '@/components/base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import { IMAGES_PATH } from '@/config/config.js';
import objUrl from '@/mixins/objUrl.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm', 'book_category'],
  mixins: [validation, objUrl],
  data() {
    return {
      Form: {
        book_category: this.book_category,
        name: '',
        author: '',
        cover: '',
        code: '',
        tags: [],
        book_copy: [
          {
            isbn: '',
            health: '',
          },
        ],
        no_issue: false,
        description: '',

        status: '', // active, closed, not_started
      },
      coverObj: '',
      IMAGES_PATH,
      errors: {},
      working: false,
      check: false,

      meta: [
        { key: 'Form.name', label: 'Book name' },
        { key: 'Form.code', label: 'Book code' },
      ],
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
    HEALTH_OPTIONS() {
      return [
        { label: 'New', value: 'new' },
        { label: 'Moderate', value: 'moderate' },
        { label: 'Old', value: 'old' },
      ];
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request', 'upload']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    addCopy() {
      this.Form.book_copy = [...this.Form.book_copy, { isbn: '', health: 0 }];
    },
    removeCopy(index) {
      this.Form.book_copy.splice(index, 1);
      this.Form.book_copy = [...this.Form.book_copy];
      this.Form = { ...this.Form };
    },
    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
      this.initial = true;
    },
    populateData() {
      Object.keys(this.oldForm).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });
      console.log('this.oldForm: ', this.oldForm);
      console.log('Form; ', this.Form);

      this.Form['status'] = ['closed', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;
    },
    async handleCreate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';
      newForm = {
        book_category: newForm.book_category,
        name: newForm.name,
        author: newForm.author,
        code: newForm.code,
        cover: newForm.cover,
        tags: newForm.tags,
        description: newForm.description,
        book_copy: newForm.book_copy,
        no_issue: newForm.no_issue,
        status: newForm.status,
      };

      const multidatas = ['book_copy', 'tags'];

      let formData = new FormData();
      Object.keys(newForm).forEach((key) => {
        if (multidatas.includes(key) && newForm[key])
          formData.append(key, JSON.stringify(newForm[key]));
        else if (newForm[key] != '' && newForm[key] != undefined)
          formData.append(key, newForm[key]);
      });

      let response = await this.upload({
        method: 'post',
        url: 'school/book',
        data: formData,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Book created successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleUpdate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      newForm = {
        book_category: newForm.book_category,
        name: newForm.name,
        author: newForm.author,
        code: newForm.code,
        cover: newForm.cover,
        tags: newForm.tags,
        description: newForm.description,
        no_issue: newForm.no_issue,
        status: newForm.status,
      };

      const multidatas = ['tags'];

      let formData = new FormData();
      Object.keys(newForm).forEach((key) => {
        if (multidatas.includes(key) && newForm[key])
          formData.append(key, JSON.stringify(newForm[key]));
        else if (newForm[key] != '' && newForm[key] != undefined)
          formData.append(key, newForm[key]);
      });

      let response = await this.upload({
        method: 'put',
        url: 'school/book/' + this.oldForm._id,
        data: formData,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Book updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },
  },
  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
