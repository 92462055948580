<template>
  <div>
    <base-title :title="'Manage Copies'" class="mb-2">
      <template #actions>
        <!-- <div class="flex space-x-3">
          <base-button
            label="New Copy"
            icon="plus"
            class="px-2 flex-shrink-0"
            @click="handleCreateCopy"
            code="sec-add"
          />
        </div> -->
      </template>
    </base-title>

    <loader type="list" :amount="6" v-if="loading"></loader>

    <div v-else-if="!loading && items.length">
      <div
        v-if="authCodes.includes('sec-view')"
        class="mt-2 grid grid-cols-3 gap-2"
      >
        <div
          class="bg-white p-2 item-grid rounded-default"
          v-for="(item, i) in items"
          :key="i"
        >
          <div class="flex justify-between w-full">
            <div class="flex space-x-2 items-center">
              <p class="text-textDarkest uppercase">
                {{ item.isbn }}
              </p>
              <div
                v-if="item.health"
                class="w-2 h-2 rounded-full bg-primary"
              ></div>
              <p class="text-textDarkest uppercase">
                {{ item.health }}
                <!-- {{ item.enrollment.name }} -->
              </p>
            </div>
            <div>
              <status-chip rounded :label="item.status" />
            </div>
          </div>
          <div></div>
          <div>
            <div class="flex space-x-5 mt-3">
              <icon
                v-if="authCodes.includes('sec-rm')"
                name="trash-alt"
                class="cursor-pointer text-textMedium"
                @click="handleDeleteItem(item._id)"
              />
              <icon
                v-if="authCodes.includes('sec-edit')"
                name="pencil-alt"
                class="cursor-pointer text-textMedium"
                @click="handleEditItem(i)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-else class="text-error flex h-88 items-center justify-center">
        You don't have a privilege to see this!
      </div> -->
    </div>
    <base-no-data v-else message="no sections added" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../../eventBus';
export default {
  data() {
    return {
      bookItem: {},
      items: [],
      loading: false,
    };
  },
  props: {
    id: { type: String },
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler: function (v) {
        if (v) {
          this.user1 = v;
          this.permission = v.permissions.find(
            (p) => p.module.name === this.module
          );
        }
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreateCopy() {
      let componentProps = { id: this.id };
      eventBus.$emit('open-modal', {
        modalId: 58,
        componentProps,
        cb: this.handleFetchCopy,
      });
    },

    async handleFetchCopy() {
      this.loading = true;
      this.items = [];
      let response = await this.request({
        method: 'get',
        url: 'school/bookCopy/' + this.id,
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
      this.loading = false;
    },

    async handleFetchBook() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: `school/book/${this.id}`,
      });
      if (response && !response.error) {
        this.bookItem = response.record;
      } else {
        this.bookItem = {};
      }
      this.loading = false;
    },

    async handleEditItem(index) {
      let componentProps = {
        oldForm: this.items[index],
      };
      eventBus.$emit('open-modal', {
        modalId: 58,
        componentProps,
        cb: this.handleFetchCopy,
      });
    },

    async handleDeleteItem(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the selected grade',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },

    /// NOTE: do not actully delete the academic year just disable it.
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/section/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'section deleted successfully',
        });
        this.handleFetchSection();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },

  async created() {
    await this.handleFetchBook();
    await this.handleFetchCopy();
  },
};
</script>

<style scoped>
.item-grid {
  animation: slider 0.5s;
}

@keyframes slider {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
