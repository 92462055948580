<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title> {{ isUpdate ? 'Update' : 'Create' }} session </template>
    <template>
      <div class="space-y-4">
        <div>
          <base-input
            label="Name"
            placeholder="name"
            v-model="Form.name"
            :errors="errors.name"
            capitalize
            check
            required
          />
        </div>
        <div>
          <base-select
            label="Category"
            placeholder="category"
            :items="sessionCategories"
            v-model="Form.category"
            :errors="errors.category"
            check
            required
          />
        </div>
        <div class="flex space-x-5">
          <base-input
            label="Start Time"
            placeholder="start time"
            v-model="Form.start"
            :errors="errors.start"
            type="time"
            class="w-1/2"
            check
            required
          />
          <base-input
            label="End Time"
            placeholder="end time"
            v-model="Form.end"
            :errors="errors.end"
            type="time"
            class="w-1/2"
            check
            required
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex justify-between">
        <base-button
          label="cancel"
          @click="handleClose"
          class="rounded-default bg-danger text-error"
        />
        <base-button
          :label="isUpdate ? 'Update' : 'Save'"
          @click="handle"
          class="rounded-default"
        />
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
//import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import { timeToMinutes } from '@/utils';
import { sessionCategories } from '@/config/constants.js';
import eventBus from '../../../../eventBus';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate },
  props: ['day', 'session'],
  mixins: [validation],
  data() {
    return {
      working: false,
      sessionCategories,
      errors: {},
      Form: {
        name: '',
        start: '',
        end: '',
        category: '',
        day: '',
      },
      meta: [
        { key: 'Form.name', label: 'Name' },
        { key: 'Form.start', label: 'Start' },
        { key: 'Form.end', label: 'End' },
        { key: 'Form.category', label: 'Category' },
      ],
      initial: true,
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name', 'start', 'end', 'category'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  // watch: {
  //   'Form.start'() {
  //     if (this.Form.end) this.validate();
  //   },

  //   'Form.end'() {
  //     if (this.Form.start) this.validate();
  //   }
  // },

  computed: {
    ...mapGetters('main', ['berror']),
    isUpdate() {
      return this.session ? true : false;
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    timeToMinutes,
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handle() {
      if (this.isUpdate) await this.handleUpdate();
      else await this.handleSave();
    },
    pad_time(time) {
      return time.length < 5 ? time.padStart(5, '0') : time;
    },
    async handleSave() {
      this.working = true;
      this.initial = false;
      if (!this.isValid()) return;
      let url = 'school/session';

      let response = await this.request({
        method: this.isUpdate ? 'put' : 'post',
        url,
        data: this.Form,
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: (this.isUpdate ? 'updated' : 'created') + ' successfully',
        });
        this.handleClose(true);
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.initial = true;
    },
    async handleUpdate() {
      this.working = true;
      this.initial = false;
      if (!this.isValid()) return;
      let url = 'school/session/' + this.session._id;

      let response = await this.request({
        method: 'put',
        url,
        data: this.Form,
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: (this.isUpdate ? 'updated' : 'created') + ' successfully',
        });
        this.handleClose(true);
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.initial = true;
    },

    populateData() {
      this.Form = {
        name: this.session.name,
        start: this.pad_time(this.session.start_time),
        end: this.pad_time(this.session.end_time),
        category: this.session.category,
        day: this.session.day,
      };
    },

    // validate() {

    //   if (
    //     this.timeToMinutes(this.Form.start) >= this.timeToMinutes(this.Form.end)
    //   ) {
    //     this.errors['start'] = [
    //       'start time can not be bigger or equal than end time.'
    //     ];
    //     this.errors['end'] = [
    //       'start time can not be bigger or equal than end time.'
    //     ];
    //   } else {
    //     this.errors['start'] = undefined;
    //     this.errors['end'] = undefined;
    //   }
    // }
  },
  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    // Object.keys(this.Form).forEach((key)=> {
    //   this.$watch(`Form.${key}`, ()=> {
    //     console.log('key', key, this.Form.key);
    //     if(['end', 'start'].includes(key) && this.timeToMinutes(this.Form.start) >= this.timeToMinutes(this.Form.end)){
    //       this.errors['end'].push('start time can not be bigger or equal than end time.')
    //       this.errors['start'] = ['start time can not be bigger or equal than end time.']
    //     }
    //   })
    // })
    if (this.isUpdate) this.populateData();
    else this.Form.day = this.day.toLowerCase();
  },
};
</script>

<style></style>
