<template>
  <div>
    <base-title :title="'Manage Adjustments'" class="mb-2">
      <template #actions>
        <div class="flex space-x-3">
          <base-button
            label="New Adjustment"
            icon="plus"
            class="px-2"
            code="gr-add"
            @click="handleAdjust"
          />
        </div>
      </template>
    </base-title>
    <BaseSwitch :items="statusOptions" v-model="status" padding="small" />

    <div class="bg-white p-1 px-4 mt-2">
      <div>
        <d-table
          #default="{ item }"
          :headers="DYNAMIC_HEADERS"
          :items="items"
          :hasActions="true"
          :handlers="DYNAMIC_HANDLERS"
          :loading="loading"
          :searchProps="searchProps"
          code="stu-view"
          @selectedItemIDs="selectedItems1($event)"
        >
          <td v-for="(header, i) in DYNAMIC_HEADERS" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'profilePic'">
                <div class="flex space-x-5 items-center">
                  <div
                    class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                  >
                    <img
                      v-if="item.added_by && item.added_by.profilePic"
                      :src="PROFILE_PATH + item.added_by.profilePic"
                      v-viewer="{ inline: false, movable: true }"
                      class="w-full h-full object-cover rounded-full cursor-pointer"
                      alt=""
                      crossorigin="anonymous"
                    />
                    <icon
                      v-else
                      :size="18"
                      name="user"
                      class="w-full h-full"
                    ></icon>
                  </div>
                  <div>
                    <p
                      class="cursor-pointer hover:underline font-semibold text-textDarkest uppercase"
                      @click="handleDetail(item.id)"
                    >
                      {{ item.added_by.fullName }}
                    </p>
                    <p class="text-textLight">{{ item.added_by.username }}</p>
                  </div>
                </div>
              </div>
              <div v-else-if="header.key === 'userId'">
                {{ item.added_by.userId }}
              </div>

              <div v-else-if="header.key === 'book'">
                <p
                  class="font-semibold uppercase text-textDarkest hover:underline cursor-pointer"
                >
                  {{ item.book.name }}
                </p>
              </div>

              <div v-else-if="header.key === 'book_copy'">
                <p v-if="item.book_copy" class="font-semibold">
                  {{ item.book_copy['isbn'].join(' | ') }}
                </p>

                <p v-else class="text-orange font-semibold">NOT ASSIGNED</p>
              </div>

              <div v-else-if="date_fields.includes(header.key)">
                <p
                  class="font-semibold uppercase text-textDarkest hover:underline cursor-pointer"
                >
                  {{ getDateTime(item[header.key]) }}
                </p>
              </div>

              <div v-else-if="header.key === 'adjustment_mode'">
                <status-chip
                  :label="item['adjustment_mode']"
                  style="max-width: fit-content"
                />
              </div>

              <div v-else-if="header.key === 'status'">
                <status-chip
                  :label="item['status']"
                  style="max-width: fit-content"
                />
              </div>
              <div v-else class="capitalize">
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString, getDateTime } from '@/utils';

import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../../eventBus';
import BaseSwitch from '@/components/base/BaseSwitch.vue';
import { PROFILE_PATH } from '@/config/config.js';

export default {
  components: { DTable, BaseSwitch },
  data() {
    return {
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Cancelled', value: 'cancelled' },
        { label: 'Rejected', value: 'rejected' },
      ],
      date_fields: [
        'requested_date',
        'adjusted_date',
        'cancelled_date',
        'rejected_date',
      ],
      status: 'pending',

      PROFILE_PATH,

      items: [],
      allowed_actions: {
        pending: ['approve', 'cancel', 'reject'],
      },
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'User Id', key: 'userId' },
        // { label: 'Role', key: 'role' },
        // { label: 'Phone Number', key: 'phoneNumber' },

        { label: 'Adjustment Mode', key: 'adjustment_mode' },

        // { label: 'ISBN ', key: 'book_copy' },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),

    DYNAMIC_HEADERS() {
      switch (this.status) {
        case 'pending':
          return [
            ...this.headers,
            { label: 'Requested At', key: 'requested_date' },
            { label: 'Status', key: 'status' },
          ]; // Show all headers for 'pending'

        case 'approved':
          return [
            ...this.headers,
            { label: 'Adjusted At', key: 'adjusted_date' },
            { label: 'Status', key: 'status' },
          ];

        case 'cancelled':
          return [
            ...this.headers,
            { label: 'Cancelled At', key: 'cancelled_date' },
            { label: 'Status', key: 'status' },
          ];
        case 'rejected':
          return [
            ...this.headers,
            { label: 'Rejected At', key: 'rejected_date' },
            { label: 'Status', key: 'status' },
          ];
        default:
          return [...this.headers, { label: 'Status', key: 'status' }]; // Default case, show all headers
      }
    },
    DYNAMIC_HANDLERS() {
      const actions = this.allowed_actions[this.status] || [];
      return [
        {
          label: 'Detail',
          icon: 'eye',
          handler: this.handleDetail,
          fullParam: true,
          // filter: () => this.authCodes.includes('enrst-rm'),
        },
        ...(actions.includes('approve')
          ? [
              {
                label: 'Approve',
                icon: 'check',
                handler: this.handleApprove,
                idParam: true,
                // filter: () => this.authCodes.includes('enrst-rm'),
              },
            ]
          : []),
        ...(actions.includes('reject')
          ? [
              {
                label: 'Reject',
                icon: 'times',
                handler: this.handleReject,
                idParam: true,
                // filter: () => this.authCodes.includes('enrst-rm'),
              },
            ]
          : []),
        ...(actions.includes('cancel')
          ? [
              {
                label: 'Cancel',
                icon: 'times',
                handler: this.handleCancel,
                idParam: true,
                // filter: () => this.authCodes.includes('enrst-rm'),
              },
            ]
          : []),
      ];
    },
  },
  watch: {
    status: {
      deep: true,
      immediate: false,
      handler: async function () {
        await this.fetchAdjusts();
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    getDateTime,

    handleDetail(item) {
      console.log('item: ', item);
      eventBus.$emit('open-modal', {
        modalId: 64,
        componentProps: { item },
      });
    },

    async handleAdjust(item) {
      eventBus.$emit('open-modal', {
        modalId: 60,
        componentProps: { item },
        cb: this.fetchAdjusts,
      });
    },

    async handleReject(id) {
      let response = await this.request({
        method: 'put',
        url: 'school/adjustment/reject/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Issue Rejected successfully',
        });
        this.fetchAdjusts();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async handleCancel(id) {
      let response = await this.request({
        method: 'put',
        url: 'school/adjustment/cancel/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Issue Cancelled successfully',
        });
        this.fetchAdjusts();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async handleApprove(id) {
      let response = await this.request({
        method: 'put',
        url: 'school/adjustment/approve/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Adjustment Approved successfully',
        });
        this.fetchAdjusts();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async fetchAdjusts() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: `school/adjustment/${this.status}`,
      });
      if (response && !response.error) this.items = response.record;
      else this.items = [];
      this.loading = false;
    },
  },

  async created() {
    await this.fetchAdjusts();
  },
};
</script>

<style scoped>
.item-grid {
  animation: slider 0.5s;
}

@keyframes slider {
  0% {
    transform: translateY(20px);
    opacity: 0%;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
</style>
