import ShelfList from '@/views/protected/library_management/shelf/List.vue';
import AdjustmentList from '@/views/protected/library_management/adjustment/List.vue';
import BookList from '@/views/protected/library_management/book/List.vue';
import CategoryList from '@/views/protected/library_management/book_category/List.vue';
import CopyList from '@/views/protected/library_management/book_copy/List.vue';
import IssueList from '@/views/protected/library_management/issue/List.vue';

import IssueBook from '@/views/protected/library_management/issue/Books.vue';

import LibraryConfig from '@/views/protected/library_management/config/Config.vue';

export default [
  {
    path: 'shelf',
    name: 'shelf',
    component: ShelfList,
  },
  {
    path: 'adjustment',
    name: 'adjustment',
    component: AdjustmentList,
  },
  {
    path: 'book',
    name: 'book',
    component: BookList,
  },
  {
    path: 'category',
    name: 'category',
    component: CategoryList,
  },
  {
    path: 'book-copy/:id',
    name: 'book-copy',
    component: CopyList,
    props: true,
  },
  {
    path: 'issue',
    name: 'issue',
    component: IssueList,
  },
  {
    path: 'issue-book',
    name: 'issue-book',
    component: IssueBook,
  },

  {
    path: 'config',
    name: 'config',
    component: LibraryConfig,
  },
];
