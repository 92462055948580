<template>
  <modal-template @cancel="handleClose">
    <template #title> AdjustMent Detail</template>
    <template #default>
      <div class="space-y-5">
        <div class="grid grid-cols-2 gap-3 my-2">
          <div
            class="border-1 col-span-1 p-4 rounded-default border-gray-100 shadow-sm"
          >
            <span class="font-bold">ADJUSTMENT INFORMATION</span>
            <!-- ADJUSTMENT INFO -->
            <div class="py-7 flex justify-center">
              <div
                class="w-32 h-32 rounded-full bg-gray-100 flex justify-center items-center"
              >
                <img
                  v-if="item.added_by.profilePic"
                  :src="PROFILE_PATH + item.added_by.profilePic"
                  v-viewer="{ inline: false, movable: true }"
                  class="w-full h-full object-cover rounded-full"
                  alt=""
                  crossorigin="anonymous"
                />
                <icon
                  v-else
                  :responsive="true"
                  name="user"
                  class="w-full h-full"
                ></icon>
              </div>
            </div>
            <div class="py-2 text-center">
              <div class="text-xl capitalize font-semibold">
                {{ item.added_by.fullName }}
              </div>
              <div class="font-sans">{{ item.added_by.userId }}</div>

              <div v-if="item.added_by['phoneNumber']" class="font-sans">
                {{ item.added_by['phoneNumber'].join(' | ') }}
              </div>
            </div>
          </div>

          <div
            class="col-span-1 border-1 p-4 rounded-default border-gray-100 shadow-sm"
          >
            <span class="font-bold">BOOK INFO</span>
            <div class="grid grid-cols-1 gap-2 my-2">
              <div class="flex space-x-2 items-center mx-4">
                <icon name="course" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.book.name }}
                </p>
              </div>
              <div class="flex space-x-2 items-center mx-4">
                <icon name="user" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.book.author }}
                </p>
              </div>
              <div class="flex space-x-2 items-center mx-4">
                <icon name="meter" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.book.book_category.name }}
                </p>
              </div>
            </div>
            <br />
          </div>
        </div>

        <div class=" ">
          <d-table
            :items="ITEMS"
            :headers="headers"
            :totalItems="ITEMS.length"
            #default="{ item, index }"
            :outlined="true"
            :multiSelect="false"
            :hideColumnSort="true"
          >
            <td
              v-for="(header, i) in headers"
              :key="i"
              class="py-2 text-textDark border p-0"
            >
              <div class="px-2">
                <div v-if="header.key === 'no'">
                  {{ index + 1 }}
                </div>
                <div v-else-if="header.key === 'isbn'">
                  <p class="text-lg font-semibold">
                    {{ item['isbn'] }}
                  </p>
                </div>
                <div
                  class="flex space-x-2 items-center"
                  v-else-if="header.key === 'health'"
                >
                  <icon
                    name="plus-square"
                    :class="{
                      'text-error': item.health === 'old',
                      'text-success': item.health === 'new',
                      'text-orange': item.health === 'moderate',
                    }"
                  />
                  <p
                    class="text-lg font-semibold text-success text-center capitalize"
                    :class="{
                      'text-error': item.health === 'old',
                      'text-success': item.health === 'new',
                      'text-orange': item.health === 'moderate',
                    }"
                  >
                    {{ item.health }}
                  </p>
                </div>
                <div class="flex" v-else-if="header.key === 'status'">
                  <status-chip
                    :label="statusChange['from']"
                    style="max-width: fit-content"
                  />
                  ->
                  <status-chip
                    :label="statusChange['to']"
                    style="max-width: fit-content"
                  />
                </div>
                <div v-else-if="header.key === 'status_reason'">
                  <status-chip
                    v-if="status_reason"
                    :label="statusChange['from']"
                    style="max-width: fit-content"
                  />
                  <p class="text-lg text-orange font-semibold capitalize">--</p>
                </div>

                <div v-else>
                  {{ getProp(item, header.key) }}
                </div>
              </div>
            </td>
          </d-table>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { mapActions, mapState } from 'vuex';
import { getPropByString } from '@/utils';
import DTable from '@/components/table/DTable.vue';

export default {
  components: { DTable, ModalTemplate },
  props: ['item'],
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapState('main', ['error']),
    headers() {
      return [
        { label: 'No', key: 'no' },
        { label: 'ISBN', key: 'isbn' },
        { label: 'Health', key: 'health' },
        { label: 'Status Change', key: 'status' },
        { label: 'Reason', key: 'status_reason' },
      ];
    },
    ITEMS() {
      return this.item.adjustment_mode == 'add'
        ? this.item.new_book_copy
        : this.item.book_copy;
    },

    statusChange() {
      return {
        from: this.item.adjustment_mode == 'add' ? 'unavailable' : 'available',
        to: this.item.adjustment_mode == 'add' ? 'available' : 'unavailable',
      };
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,

    handleClose(invokeCb = false) {
      this.$emit('close', { invokeCb });
    },
  },
};
</script>

<style></style>
