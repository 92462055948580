<template>
  <div
    class="h-full bg-primary overflow-y-auto px-2 py-5 transform duration-500 print:hidden shadow-xl flex flex-col justify-between"
    :class="{ ' w-12': collapsed, 'w-64': !collapsed }"
  >
    <div class="">
      <div class="flex items-center justify-end mb-2">
        <div
          class="bg-gray-100 p-2 rounded-full cursor-pointer"
          @click="handleToggleCollapse"
        >
          <icon name="bars" class="text-textDarkest" />
        </div>
      </div>
      <div v-if="activeNavs">
        <div
          v-for="(nav, index) in activeNavs"
          :key="index"
          class="py-2 relative cursor-pointer hover:bg-gray-200 hover:text-textDarkest rounded-lg my-1"
          :class="
            activePath == nav.path
              ? 'text-textDarkest bg-gray-100'
              : 'text-white'
          "
          @click="handleNavigation(nav.path)"
        >
          <div
            v-if="activePath == nav.path && !collapsed"
            class="absolute left-0 top-0 bottom-0 flex items-center"
          >
            <div class="w-1 h-3 rounded-sm bg-primary"></div>
          </div>
          <div v-if="!collapsed" class="flex items-center space-x-4 pl-8">
            <icon :name="nav.icon" />
            <div>{{ nav.label }}</div>
          </div>
          <div
            v-else
            v-tippy="{
              arrow: false,
              placement: 'right',
              animation: 'scale',
              theme: 'black',
            }"
            :content="nav.label"
            class="flex items-center justify-center py-0 mx-1"
          >
            <icon :name="nav.icon" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid bg-white bg-opacity-25 p-1 rounded-lg items-center cursor-pointer border-1 border-white border-opacity-50 hover:bg-opacity-50 duration-300"
      :class="collapsed ? 'grid-cols-1' : 'grid-cols-8'"
      @click="showSchoolDetails"
    >
      <div class="col-span-2 flex justify-center items-center">
        <div
          class="w-10 bg-gray-100 rounded-full overflow-hidden"
          :class="collapsed ? 'h-6' : ' h-10'"
          v-if="school.logo && school.logo != 'undefined'"
        >
          <img
            :src="LOGO_PATH + school.logo"
            class="object-cover w-full"
            alt=""
          />
        </div>
        <div
          class="bg-gray-100 rounded-full flex items-center justify-center"
          :class="collapsed ? 'p-1' : 'p-3'"
          v-else
        >
          <icon name="school" class="text-textDarkest" />
        </div>
      </div>
      <p class="capitalize col-span-6 text-white" v-if="!collapsed">
        {{ school.name }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import eventBus from '../../../eventBus';
import { LOGO_PATH } from '@/config/config.js';

export default {
  data() {
    return {
      collapsed: false,
      static_roles: ['super_admin', 'registrar'],
      school: {},
      LOGO_PATH,
      navs: {
        school: [
          {
            label: 'Enrollment Types',
            name: 'enrollment-type',
            icon: 'graduation-cap',
            slug: 'cls',
            code: 'sch-gr', //sch-er
            path: '/school/enrollment-type-list',
            allowed_for: ['super_admin', 'registrar', 'admin'],
          },
          {
            label: 'Grades',
            name: 'grade',
            icon: 'atom',
            slug: 'cls',
            code: 'sch-gr',
            path: '/school/grade-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Sections',
            name: 'section',
            icon: 'chalkboard-user',
            slug: 'cls',
            code: 'sch-sec',
            path: '/school/section-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Academic Years',
            name: 'academic-year',
            icon: 'calendar',
            slug: 'str',
            code: 'sch-aca',
            path: '/school/academic-year',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Subjects',
            name: 'subjects',
            icon: 'list-alt',
            slug: 'cor',
            code: 'sch-sub',
            path: '/school/subject',
            allowed_for: ['super_admin', 'registrar'],
          },
          // {
          //   icon: 'school',
          //   name: 'my-school',
          //   slug: 'sch',
          //   label: 'My School',
          //   path: '/school/my-school',
          //   allowed_for: [],
          // },
        ],

        registrations: [
          {
            label: 'Students',
            name: 'students',
            icon: 'user-graduate',
            slug: 'stu',
            code: 'adm-stu',
            path: '/registrations/student',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Teachers',
            name: 'teachers',
            icon: 'chalkboard-user',
            slug: 'tr',
            code: 'adm-thr',
            path: '/registrations/teacher',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'School Officials',
            name: 'school-official',
            icon: 'school',
            slug: 'sco',
            code: 'adm-stf',
            path: '/registrations/school-official',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Parents',
            name: 'parents',
            icon: 'family-1',
            slug: 'par',
            code: 'adm-par',
            path: '/registrations/parent',
            allowed_for: ['super_admin', 'registrar'],
          },
          // {
          //   label: 'Migration',
          //   name: 'parents',
          //   icon: 'file-upload',
          //   slug: 'par',
          //   code: 'adm-stu',
          //   path: '/registrations/import-students',
          //   allowed_for: ['super_admin', 'registrar'],
          // },
          // { icon: 'user', label: 'Officials', path: '/registrations/official' },
        ],

        enrollment: [
          {
            label: 'Student Enrollment',
            name: 'academic-section',
            icon: 'compress',
            slug: 'cls',
            code: 'enrl-stu',
            path: '/enrollment/academic-section',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Course Enrollment',
            name: 'teacher-course',
            icon: 'signature',
            slug: 'cls',
            code: 'enrl-cor',
            path: '/enrollment/teacher-course',
            allowed_for: ['super_admin', 'registrar'],
          },

          {
            label: 'Enrollment History',
            name: 'enrollment-history',
            icon: 'history',
            slug: 'cls',
            code: 'enrl-stu',
            path: '/enrollment/enrollment-history',
            allowed_for: ['super_admin', 'registrar'],
          },

          {
            label: 'Grade Promotion',
            name: 'grade-promotion',
            icon: 'exchange',
            slug: 'cls',
            code: 'enrl-stu',
            path: '/enrollment/grade-promotion',
            allowed_for: ['super_admin', 'registrar'],
          },

          {
            label: 'Graduate Students',
            name: 'graduate-students',
            icon: 'user-graduate',
            slug: 'cls',
            code: 'enrl-stu',
            path: '/enrollment/graduate-students',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Student Id',
            icon: 'print',
            slug: 'cls',
            code: 'enrl-stu',
            path: '/enrollment/student_id',
          },
        ],

        schedule: [
          {
            label: 'Calendar',
            icon: 'calendar',
            slug: 'cal',
            code: 'tmt-cal',
            path: '/schedule/calendar',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Session',
            icon: 'gear',
            slug: 'scd',
            code: 'tmt-ses',
            path: '/schedule/session',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Schedule',
            icon: 'clock',
            slug: 'scd',
            code: 'tmt-sdl',
            path: '/schedule/period',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        attendance: [
          {
            label: 'Overview',
            icon: 'meter',
            slug: 'att',
            code: 'att-da',
            path: '/attendance/dashboard',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Attendance',
            icon: 'attendance-1',
            slug: 'att',
            code: 'att-att',
            path: '/attendance/detail',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        academics: [
          {
            label: 'Grade Verification',
            icon: 'check-circle',
            slug: 'att',
            code: 'att-da',
            path: '/academics/grade_verification',
          },

          {
            label: 'Student Roaster',
            icon: 'meter',
            slug: 'att',
            code: 'att-da',
            path: '/academics/student_roaster',
          },
          {
            label: 'Mark List',
            name: 'mark-list',
            icon: 'address-book',
            slug: 'cls',
            code: 'aca-mkl',
            path: '/academics/mark-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Certificate',
            icon: 'print',
            slug: 'att',
            code: 'att-da',
            path: '/academics/student_copy',
          },
          // {
          //   label: 'Academic Status',
          //   icon: 'address-book',
          //   slug: 'att',
          //   code: 'att-da',
          //   path: '/academics/academic_status',
          // },
          // {
          //   label: 'Withdrawal',
          //   icon: 'exchange',
          //   slug: 'att',
          //   code: 'att-da',
          //   path: '/academics/other_status',
          // },
          // {
          //   label: 'RE Exam',
          //   icon: 'hourglass',
          //   slug: 'att',
          //   code: 'att-da',
          //   path: '/academics/re_exam',
          // },
        ],

        library: [
          {
            label: 'Shelf',
            icon: 'check-circle',
            slug: 'att',
            code: 'att-da',
            path: '/library/shelf',
          },

          {
            label: 'Category',
            icon: 'meter',
            slug: 'att',
            code: 'att-da',
            path: '/library/category',
          },
          {
            label: 'Books',
            icon: 'address-book',
            slug: 'cls',
            code: 'aca-mkl',
            path: '/library/book',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Issue Book',
            icon: 'exchange',
            slug: 'att',
            code: 'att-da',
            path: '/library/issue-book',
          },
          {
            label: 'Issues',
            icon: 'exchange',
            slug: 'att',
            code: 'att-da',
            path: '/library/issue',
          },
          {
            label: 'Adjustment',
            icon: 'address-book',
            slug: 'att',
            code: 'att-da',
            path: '/library/adjustment',
          },
          {
            label: 'Config',
            icon: 'gear',
            slug: 'att',
            code: 'att-da',
            path: '/library/config',
          },
        ],

        fee: [
          {
            icon: 'info-circle',
            label: 'Payment Type',
            slug: 'pty',
            code: 'fee-pt',
            path: '/fee/payment-types',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'atom',
            label: 'Transport Group',
            slug: 'grp',
            code: 'fee-pt',
            path: '/fee/group',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'dollar-sign',
            label: 'Payment Group',
            slug: 'pa',
            code: 'fee-pg',
            path: '/fee/payment-group',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'user',
            label: 'Payer',
            slug: 'pyr',
            code: 'fee-pyr',
            path: '/fee/payers',
            allowed_for: ['super_admin', 'registrar'],
          },

          {
            icon: 'exchange-alt',
            label: 'Discount',
            slug: 'dsc',
            code: 'fee-dis',
            path: '/fee/discount',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'cog',
            label: 'Generate Bill',
            slug: 'dsc',
            code: 'fee-gen',
            path: '/fee/generate-bill',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        'shl-management': [
          {
            label: 'Academic Year',
            icon: 'user',
            slug: 'acy',
            code: 'adm-stf',
            path: '/shl-management/academic-year',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Grade',
            icon: 'user',
            slug: 'cls',
            path: '/shl-management/grade-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Section',
            icon: 'user',
            slug: 'sec',
            path: '/shl-management/section-list',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        grading: [
          {
            icon: 'meter',
            label: 'Overview',
            slug: 'gr',
            path: '/grading/overview',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        settings: [
          {
            icon: 'gear',
            name: 'school-pref',
            label: 'School Preferences',
            slug: 'rl',
            code: 'set-pre',
            path: '/settings/school-pref',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'chart-bar',
            name: 'role',
            slug: 'rl',
            code: 'set-rl',
            label: 'Roles',
            path: '/settings/roles',
            allowed_for: ['super_admin', 'shl_registrar'],
          },
          {
            icon: 'key',
            name: 'permissions',
            slug: 'rl',
            code: 'set-pm',
            label: 'Permissions',
            path: '/settings/permissions',
            allowed_for: ['super_admin', 'shl_registrar'],
          },
          {
            icon: 'meter',
            name: 'assessments',
            slug: 'rl',
            code: 'set-pm',
            label: 'Assessments',
            path: '/settings/assessments',
          },
          {
            icon: 'list-alt',
            name: 'comm-book',
            slug: 'rl',
            code: 'set-pm',
            label: 'Communication Book',
            path: '/settings/comm-book',
            allowed_for: ['super_admin', 'shl_registrar'],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['user', 'authCategories']),
    ...mapGetters('main', ['activeNav']),

    activeNav() {
      return this.$route.path.split('/')[1];
    },
    activePath() {
      return this.$route.fullPath;
    },

    activeNavs() {
      return this.navs[this.activeNav.toLowerCase()].filter((nav) =>
        this.authCategories.find((cat) => cat == nav.code)
      );
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    handleNavigation(path) {
      if (this.$route.fullPath !== path) this.$router.push({ path });
      else window.location.reload();
    },

    handleToggleCollapse() {
      this.collapsed = !this.collapsed;
      eventBus.$emit('collapse', this.collapsed);
    },
    route(val) {
      if (val.length) {
        this.$router.push(`${val[0].path}`);
      }
    },
    async getSchool() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile/my-school',
      });
      this.school = response && response.record ? response.record : {};
    },
    showSchoolDetails() {
      let componentProps = {
        school: this.school,
      };
      eventBus.$emit('open-modal', {
        modalId: 52,
        componentProps,
      });
    },
  },
  watch: {
    // activeNavs: {
    //   immediate: true,
    //   handler(val) {
    //     this.route(val)
    //     // if (!this.activeNavs || this.activeNavs.length == 0) return
    //     // let pathAuthorized = this.activeNavs.some(({ path }) => path === this.activePath)
    //     // if (pathAuthorized) return
    //     // this.$router.push({ path: this.activeNavs[0].path })
    //   },
    // },
  },
  created() {
    this.getSchool();
  },
};
</script>

<style></style>
