<template>
  <div class="w-full">
    <p v-if="label" class="mb-0">
      {{ label }}<span v-if="required" class="text-error">*</span>
    </p>
    <div class="mt-0">
      <textarea
        :rows="rows"
        :style="'font-size: ' + size + 'px;'"
        v-bind="$attrs"
        @input="handleInput"
        class="w-full border-1 border-dgray rounded text-textDarkest focus:outline-none focus:bg-white focus:ring-2 focus:ring-secondary"
        :class="{
          'bg-gray-100': success && !bordered,
          'bg-danger': !success && !bordered,
          'border-textLight': success && bordered,
          'border-error': !success && bordered,
          'border rounded': bordered,
          'py-2 px-3': padding === 'big',
          'py-1 px-2': padding === 'small',
          'rounded-none': rounded,
          'rounded-default': !rounded,
        }"
      ></textarea>
    </div>
    <div v-if="!success" class="text-xs" style="color: red">
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: String,
    size: { type: Number, default: 16 },
    errors: { type: Array, default: () => [] },
    bordered: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    rows: { type: Number, default: 1 },
    padding: { type: String, default: ['big', 'small'][0] },
    rounded: { type: Boolean, default: false },
  },
  computed: {
    success() {
      return this.errors.length === 0;
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style>
.custom-text-input::placeholder {
  font-size: 14px;
}
</style>
