<template>
  <div>
    <base-title :title="'Manage Issues'">
      <template #actions>
        <div class="flex space-x-3"></div>
      </template>
    </base-title>

    <BaseSwitch :items="statusOptions" v-model="status" padding="small" />

    <div class="bg-white p-1 px-4 mt-2">
      <div>
        <d-table
          #default="{ item }"
          :headers="DYNAMIC_HEADERS"
          :items="items"
          :hasActions="ACTION"
          :handlers="DYNAMIC_HANDLERS"
          :loading="loading"
          :searchProps="searchProps"
          code="stu-view"
          @selectedItemIDs="selectedItems1($event)"
        >
          <td v-for="(header, i) in DYNAMIC_HEADERS" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'profilePic'">
                <div class="flex space-x-5 items-center">
                  <div
                    class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                  >
                    <img
                      v-if="item.user && item.user.profilePic"
                      :src="PROFILE_PATH + item.user.profilePic"
                      v-viewer="{ inline: false, movable: true }"
                      class="w-full h-full object-cover rounded-full cursor-pointer"
                      alt=""
                      crossorigin="anonymous"
                    />
                    <icon
                      v-else
                      :size="18"
                      name="user"
                      class="w-full h-full"
                    ></icon>
                  </div>
                  <div>
                    <p
                      class="cursor-pointer hover:underline font-semibold text-textDarkest uppercase"
                      @click="handleDetail(item.id)"
                    >
                      {{ item.user.fullName }}
                    </p>
                    <p class="text-textLight">{{ item.user.username }}</p>
                  </div>
                </div>
              </div>
              <div v-else-if="header.key === 'userId'">
                {{ item.user.userId }}
              </div>

              <div
                v-else-if="header.key === 'role'"
                class="flex space-x-2 items-center"
              >
                <icon
                  :name="USER_ICON[item.user_info.user_type]"
                  class="text-secondary"
                />
                <p class="text-sm font-semibold text-center capitalize">
                  {{ item.user_info.user_type.replace('_', ' ') }}
                </p>
              </div>

              <div v-else-if="header.key === 'book'">
                <p
                  class="font-semibold uppercase text-textDarkest hover:underline cursor-pointer"
                >
                  {{ item.book.name }}
                </p>
              </div>

              <div v-else-if="header.key === 'book_copy'">
                <p v-if="item.book_copy" class="font-semibold">
                  {{ item.book_copy.isbn }}
                </p>

                <p v-else class="text-orange font-semibold">NOT ASSIGNED</p>
              </div>

              <div v-else-if="date_fields.includes(header.key)">
                <p
                  class="font-semibold uppercase text-textDarkest hover:underline cursor-pointer"
                >
                  {{ getDateTime(item[header.key]) }}
                </p>
              </div>
              <!-- <div v-else-if="header.key === 'phoneNumber'">
                {{ item.user['phoneNumber'].join(' | ') }}
              </div> -->

              <div v-else-if="header.key === 'status'">
                <status-chip
                  :label="item['status']"
                  style="max-width: fit-content"
                />
              </div>
              <div v-else class="capitalize">
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString, getDateTime } from '@/utils';
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';
import BaseSwitch from '@/components/base/BaseSwitch.vue';

export default {
  components: { DTable, BaseSwitch },
  data() {
    return {
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Queued', value: 'queued' },
        { label: 'Approved', value: 'approved' },
        { label: 'Returned', value: 'returned' },
        { label: 'Not Returned', value: 'not_returned' },
        { label: 'Expired', value: 'expired' },
        { label: 'Cancelled', value: 'cancelled' },
        { label: 'Rejected', value: 'rejected' },
      ],
      date_fields: [
        'queued_date',
        'issued_date',
        'taken_date',
        'expiry_date',
        'expected_date',
        'returned_date',
        'cancelled_date',
        'rejected_date',
      ],

      status: 'pending',

      PROFILE_PATH,
      allowed_actions: {
        pending: ['approve', 'reject'],
        approved: ['return'], //report to be added
        not_returned: ['return'], // report to be added
      },
      selectedItems: [],
      items: [],
      loading: false,
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'User Id', key: 'userId' },
        { label: 'Role', key: 'role' },
        // { label: 'Phone Number', key: 'phoneNumber' },

        { label: 'Book ', key: 'book' },

        { label: 'ISBN ', key: 'book_copy' },
      ],

      searchProps: {
        searchUrl: 'student/studentProfile/table-search',
      },
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),

    ACTIONS() {
      return ['pending', 'approved', 'not_returned'];
    },
    ACTION() {
      if (this.ACTIONS.includes(this.status)) return true;
      else return false;
    },

    USER_ICON() {
      return {
        student: 'user-graduate',
        teacher: 'chalkboard-user',
        school_official: 'school',
      };
    },
    DYNAMIC_HEADERS() {
      switch (this.status) {
        case 'pending':
          return [
            ...this.headers,
            { label: 'Issued At', key: 'issued_date' },
            { label: 'Status', key: 'status' },
          ]; // Show all headers for 'pending'
        case 'queued':
          return [
            ...this.headers,
            { label: 'Queued At', key: 'queued_date' },
            { label: 'Queue No', key: 'queue' },
            { label: 'Status', key: 'status' },
          ];
        case 'approved':
          return [
            ...this.headers,
            // { label: 'Issued At', key: 'issued_date' },
            { label: 'Taken At', key: 'taken_date' },
            { label: 'Expected At', key: 'expected_date' },
            { label: 'Status', key: 'status' },
          ];
        case 'returned':
          return [
            ...this.headers,
            // { label: 'Issued At', key: 'issued_date' },
            { label: 'Taken At', key: 'taken_date' },
            // { label: 'Expected At', key: 'expected_date' },
            { label: 'Returned At', key: 'returned_date' },
            { label: 'Status', key: 'status' },
          ];
        case 'not_returned':
          return [
            ...this.headers,
            // { label: 'Issued At', key: 'issued_date' },
            { label: 'Taken At', key: 'taken_date' },
            { label: 'Expected At', key: 'expected_date' },
            { label: 'Status', key: 'status' },
          ];
        case 'expired':
          return [
            ...this.headers,
            { label: 'Issued At', key: 'issued_date' },
            { label: 'Expired At', key: 'expiry_date' },

            { label: 'Status', key: 'status' },
          ];
        case 'cancelled':
          return [
            ...this.headers,
            { label: 'Cancelled At', key: 'cancelled_date' },
            { label: 'Status', key: 'status' },
          ];
        case 'rejected':
          return [
            ...this.headers,
            { label: 'Rejected At', key: 'rejected_date' },
            { label: 'Status', key: 'status' },
          ];
        default:
          return [...this.headers, { label: 'Status', key: 'status' }]; // Default case, show all headers
      }
    },
    DYNAMIC_HANDLERS() {
      const actions = this.allowed_actions[this.status] || [];
      return [
        ...(actions.includes('approve')
          ? [
              {
                label: 'Approve',
                icon: 'check',
                handler: this.handleApprove,
                fullParam: true,
                // filter: () => this.authCodes.includes('enrst-rm'),
              },
            ]
          : []),
        ...(actions.includes('reject')
          ? [
              {
                label: 'Reject',
                icon: 'times',
                handler: this.handleReject,
                idParam: true,
                // filter: () => this.authCodes.includes('enrst-rm'),
              },
            ]
          : []),
        ...(actions.includes('return')
          ? [
              {
                label: 'Return',
                icon: 'logout',
                handler: this.handleReturn,
                fullParam: true,
                // filter: () => this.authCodes.includes('enrst-rm'),
              },
            ]
          : []),
        ...(actions.includes('report')
          ? [
              {
                label: 'Report',
                icon: 'signature',
                handler: this.handleReport,
                fullParam: true,
                // filter: () => this.authCodes.includes('enrst-rm'),
              },
            ]
          : []),
      ];
    },
  },
  watch: {
    status: {
      deep: true,
      immediate: false,
      handler: async function () {
        await this.fetchIssues();
      },
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    getDateTime,
    selectedItems1(e) {
      this.selectedItems = e;
    },
    async handleApprove(item) {
      eventBus.$emit('open-modal', {
        modalId: 61,
        componentProps: { item },
        cb: this.refresh,
      });
    },

    async handleReject(id) {
      let componentProps = {
        message: 'Are you sure you want to reject this issue',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedReject,
        cbParams: { id },
      });
    },

    async handleReturn(item) {
      eventBus.$emit('open-modal', {
        modalId: 62,
        componentProps: { item, isReturn: true },
        cb: this.refresh,
      });
    },

    async handleReport(item) {
      eventBus.$emit('open-modal', {
        modalId: 62,
        componentProps: { item, isReturn: false },
        cb: this.refresh,
      });
    },
    async refresh() {
      await this.fetchIssues();
    },

    async proceedReject({ id }) {
      let response = await this.request({
        method: 'put',
        url: 'school/issue/reject/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Issue Rejected successfully',
        });
        this.fetchIssues();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    handleDetail(id) {
      console.log('detail here for the exempted courses', id);
    },
    async fetchIssues() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: `school/issue/${this.status}`,
      });
      if (response && !response.error) this.items = response.record;
      else this.items = [];
      this.loading = false;
    },
  },
  async created() {
    await this.fetchIssues();
  },
};
</script>

<style></style>
