<template>
  <div class="space-y-2 text-sm">
    <base-title :title="'Mass Update Student Payment Information'">
      <template #actions>
        <div class="flex flex-wrap px-4 py-2">
          <base-button
            :working="working"
            label="Mass Update"
            icon="exchange"
            class="px-2 text-sm rounded"
            @click="handleMassUpdate"
          />
        </div>
      </template>
    </base-title>
    <div class="flex space-x-2 items-center">
      <base-title title="Select Payment Info To Be Updated" />
      <div v-for="(field, i) in FIELDS" :key="i">
        <div
          class="flex space-x-2 items-center border p-2 cursor-pointer hover:bg-brightGray text-textDark hover:text-primary"
          :class="
            payment_fields[field.value]
              ? 'border-primary'
              : 'border-transparent'
          "
          @click="toggleHeader(field.value)"
        >
          <div
            style="padding: 0.375rem"
            :class="
              payment_fields[field.value]
                ? 'bg-primary'
                : 'border-2 border-primary'
            "
            class="rounded-sm"
          >
            <icon
              name="check"
              fill="#fff"
              :size="payment_fields[field.value] ? 14 : 10"
            />
          </div>

          <div class="capitalize">{{ field.label }}</div>
        </div>
      </div>
    </div>
    <div class="space-y-1 p-8">
      <data-table :headers="headers" :items="students" :loading="loading">
        <template #default="{ index }">
          <td
            v-for="header in headers"
            :key="header.key"
            class="align-top"
            :style="{
              width: WIDE_FIELDS.includes(header.key) ? '200px' : undefined,
            }"
          >
            <div
              v-if="header.key === 'userId'"
              class="flex space-x-2 items-center"
            >
              <div class="flex-auto">
                <base-input
                  :style="{
                    width: '150px',
                  }"
                  type="text"
                  v-model="students[index].userId"
                  :errors="errors[index].userId"
                  bordered
                  padding="small"
                  required
                  @blur="fetchStudentByUserId(students[index].userId, index)"
                  @enter="addRow(index)"
                />
              </div>
            </div>
            <div
              v-else-if="header.key === 'fullName'"
              class="flex space-x-2 items-center"
            >
              <div class="flex-auto">
                <base-input
                  type="text"
                  v-model="students[index].fullName"
                  :errors="errors[index].fullName"
                  bordered
                  disabled
                  padding="small"
                />
              </div>
            </div>
            <div v-else-if="header.key === 'grade'" class="px-1">
              <base-input
                type="text"
                v-model="students[index].grade"
                :errors="errors[index].grade"
                bordered
                disabled
                padding="small"
              />
            </div>
            <div
              v-else-if="header.key === 'section'"
              class="flex space-x-4 items-start"
            >
              <base-input
                type="text"
                v-model="students[index].section"
                :errors="errors[index].section"
                bordered
                disabled
                padding="small"
                class="flex-auto"
              />
            </div>
            <div
              v-else-if="header.key === 'payer'"
              class="flex space-x-4 items-start px-1"
            >
              <base-select
                searchable
                :items="PAYER_OPTIONS"
                placeholder="Payer"
                v-model="students[index].payer"
                :errors="errors[index].payer"
              />
            </div>
            <div
              v-else-if="header.key === 'discount'"
              class="flex space-x-4 items-start"
            >
              <base-select
                searchable
                clearable
                :items="DISCOUNT_OPTIONS"
                placeholder="Discount"
                v-model="students[index].discount"
                :errors="errors[index].discount"
              />
            </div>
            <div
              v-else-if="header.key === 'transport'"
              class="flex space-x-4 items-start"
            >
              <base-select
                :items="GROUP_OPTIONS"
                placeholder="Transport"
                v-model="students[index].transport"
                :errors="errors[index].transport"
                searchable
                clearable
              />
            </div>
            <div
              v-else-if="header.key === 'action'"
              class="flex space-x-4 items-start"
            >
              <div
                class="p-3 bg-brighterGray text-textMedium hover:bg-brightGray hover:text-error cursor-pointer active:bg-danger rounded-full"
                @click="removeRow(index)"
              >
                <Icon name="times" :size="10" />
              </div>
            </div>
            <div v-else class="flex space-x-4 items-start px-1">
              <base-input
                v-model="students[index][header.key]"
                :errors="errors[index][header.key]"
                bordered
                disabled
                padding="small"
                class="flex-auto"
              />
            </div>
          </td>
        </template>
        <template #no-data>
          <div :style="!allValid ? { color: '#ec3118' } : {}">No Record</div>
        </template>
      </data-table>
      <div class="flex space-x-2 justify-end">
        <input
          v-if="fileSelector"
          type="file"
          class="hidden"
          ref="csv-file-input"
          @input="handleFileSelect"
        />
        <base-button
          :label="importing ? 'importing' : 'import csv'"
          :primary="false"
          class="text-sm px-2 rounded"
          :class="{ 'bg-brightGray hover:text-primary': !importing }"
          :working="importing"
          @click="openFileSelector"
        />
        <base-button
          label="remove all rows"
          :primary="false"
          class="text-sm px-2 rounded bg-brightGray hover:text-primary"
          @click="removeAllRows"
        />
        <base-button
          label="add row"
          :primary="false"
          class="text-sm px-2 rounded bg-brightGray hover:text-primary"
          @click="addRow(students.length - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import DataTable from '@/components/table/DTable.vue';
import eventBus from '@/eventBus';
// import eventBus from '@/eventBus';
export default {
  props: ['ay_id'],
  components: { DataTable },
  mixins: [validation],
  data() {
    return {
      allValid: false,
      working: false,
      loading: false,
      academic_year: {},
      Form: {
        userId: undefined,
        fullName: undefined,
        grade: undefined,
        section: undefined,
        payer: undefined,
        discount: undefined,
        transport: undefined,
      },
      payment_fields: {
        payer: true,
        discount: true,
        transport: true,
      },
      students: [],
      payer_list: [],
      discount_list: [],
      group_list: [],
      errors: [],
      formErrors: {},
      importing: false,
      unwathcCallbacks: [],
      fileSelector: false,
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    headers() {
      return [
        { key: 'userId', label: 'USER ID' },
        { key: 'fullName', label: 'FULL NAME' },
        { key: 'grade', label: 'GRADE' },
        { key: 'section', label: 'SECTION' },
        { key: 'payer', label: 'PAYER' },
        { key: 'discount', label: 'DISCOUNT' },
        { key: 'transport', label: 'TRANSPORT' },

        { key: 'action', label: undefined },
      ];
    },
    formTemplate() {
      let template = {
        userId: undefined,
        fullName: undefined,
        grade: undefined,
        section: undefined,
        payer: undefined,
        discount: undefined,
        transport: undefined,
      };

      return template;
    },
    WIDE_FIELDS() {
      return ['fullName', 'payer', 'discount', 'transport'];
    },
    FIELDS() {
      return [
        { label: 'Payer', value: 'payer' },
        { label: 'Discount', value: 'discount' },
        { label: 'Transport', value: 'transport' },
      ];
    },

    PAYER_OPTIONS() {
      return this.payer_list.map((payer) => ({
        label: payer.name,
        value: payer._id,
      }));
    },
    DISCOUNT_OPTIONS() {
      return this.discount_list.map((discount) => ({
        label: discount.name,
        value: discount._id,
      }));
    },
    GROUP_OPTIONS() {
      return this.group_list.map((group) => ({
        label: group.name,
        value: group._id,
      }));
    },

    totalCustomers() {
      return this.students.length;
    },
    customers() {
      return this.students;
      //   return this.students.map((payee) =>
      //     Object.keys(this.formTemplate).reduce(
      //       (acc) => ({
      //         ...acc,
      //       }),
      //       {}
      //     )
      //   );
    },
  },
  watch: {
    // customers() {
    //   this.smartSet({ stateKey: 'customers', data: this.customers });
    // },

    initial: {
      immediate: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    Form: {
      immediate: false,
      deep: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    students: {
      immediate: true,
      deep: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    headers() {
      this.validationHandler();
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    validationHandler() {
      this.resetErrors(0);
      if (!this.initial) {
        this.students.forEach((payee, i) =>
          Object.keys(this.formTemplate).forEach((key) => {
            if (!payee[key])
              this.errors[i][key] = [
                this.headers.find((header) => header.key === key).label +
                  ' is required',
              ];
            else this.errors[i][key] = [];
          })
        );
        Object.keys(this.Form).forEach((key) => {
          if (!this.Form[key]) {
            this.formErrors[key] = `${key} is required`;
          } else {
            this.formErrors[key] = ``;
          }
        });
      }
    },

    toggleHeader(field) {
      this.payment_fields[field] = !this.payment_fields[field];
    },

    addRow(index) {
      this.students = [
        ...this.students.slice(0, index + 1),
        { ...this.formTemplate },
        ...this.students.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
      ];
      console.log('this.students: ', this.students);
    },
    removeRow(index) {
      this.students = [
        ...this.students.slice(0, index),
        ...this.students.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
      ];
    },
    removeAllRows() {
      this.students = [];
      this.errors = [];
    },
    importCsv(file) {
      this.importing = true;
      this.$papa.parse(file, {
        skipEmptyLines: true,
        complete: ({ data }) => {
          this.importing = false;
          // let lastIndex = this.students.length;
          data.slice(1).forEach((bill) => {
            const student_info = Object.keys(this.formTemplate).reduce(
              (student, key, i) => ({ ...student, [key]: bill[i] }),
              {}
            );
            this.students.push(student_info);
          });
          this.resetFile();
          this.populateData();
        },
      });
    },
    handleFileSelect() {
      let file = this.$refs['csv-file-input'].files[0];
      this.importCsv(file);
    },
    openFileSelector() {
      this.fileSelector = true;
      this.$nextTick(() => {
        this.$refs['csv-file-input'].click();
      });
    },
    resetFile() {
      this.fileSelector = false;
    },
    resetErrors(startIndex) {
      if (startIndex < 0) startIndex = 0;
      this.students
        .slice(startIndex)
        .forEach(
          (payee, i) =>
            (this.errors[i + startIndex] = Object.keys(
              this.formTemplate
            ).reduce((acc, key) => ({ ...acc, [key]: [] }), {}))
        );
    },
    resetForm() {
      this.students = [];
    },
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async preMassUpdate() {
      this.initial = false;
      //   if (!this.isValid()) return;
      let componentProps = {
        title: `Student Payment Info Mass Update `,
        message:
          'Are you sure you really want to update payment infos of ' +
          this.totalCustomers.toLocaleString() +
          ' total students',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.handleMassUpdate,
      });
    },

    async populateData() {
      this.working = true;
      this.loading = true;

      let index = 0;
      const response = await this.request({
        method: 'get',
        url: `student/studentProfile/get-verified`,
      });
      if (response && !response.error) {
        for (const student of this.students) {
          const students = response.record;
          const current_student = students.find(
            (stu) => stu.userId == student.userId
          );
          console.log('student: ', student);

          if (current_student) {
            this.students[index]._id = current_student._id;
            this.students[index].fullName = current_student.fullName;
            this.students[index].grade = current_student.class.name;
            this.students[index].section = current_student.section
              ? current_student.section.name
              : '-';

            const my_payer = this.payer_list.find(
              (payer) => payer.name == student.payer
            );
            const my_discount = this.discount_list.find(
              (discount) => discount.name == student.discount
            );
            const my_transport = this.group_list.find(
              (group) => group.name == student.transport
            );

            const default_payer = this.payer_list.find(
              (payer) => payer.name == 'self'
            );

            this.students[index].payer = my_payer
              ? my_payer._id
              : default_payer._id;

            this.students[index].discount = my_discount
              ? my_discount._id
              : null;
            this.students[index].transport = my_transport
              ? my_transport._id
              : null;

            index++;

            // this.errors[index]['userId'] = [''];
          } else {
            // this.errors[index]['userId'] = ['Not found'];
            // this.students[index].fullName = '-';
            // this.students[index].grade = '-';
            // this.students[index].section = '-';
            // this.students[index].payer = '-';
            // this.students[index].discount = '-';
            // this.students[index].transport = '-';
            this.removeRow(index);
          }
        }
        this.working = false;
        this.loading = false;
      } else {
        this.working = false;
        this.loading = false;
        eventBus.$emit('open-toast', {
          message: 'There are no active students in this school',
          error: true,
        });
      }

      this.students = [...this.students];
      this.working = false;
      this.loading = false;
    },
    async fetchStudentByUserId(userId, index) {
      const student_id = encodeURIComponent(`${userId}`);
      let response = await this.request({
        method: 'get',
        url: `student/studentProfile/get-by-userId/${student_id}`,
      });
      if (response) {
        this.students[index]._id = response.record._id;
        this.students[index].fullName = response.record.fullName;
        this.students[index].grade = response.record.class.name;
        this.students[index].section = response.record.section
          ? response.record.section.name
          : '-';
        this.students[index].payer = response.record.payer
          ? response.record.payer
          : 'self';
        this.students[index].discount = response.record.discount;

        this.students[index].transport = response.record.transport;

        this.students = [...this.students];

        this.errors[index]['userId'] = [''];
      } else {
        this.errors[index]['userId'] = ['Not found'];
      }
    },
    async handleMassUpdate() {
      this.working = true;

      const students = this.students.map((student) => {
        const { _id, userId, fullName, payer, discount, transport } = student;

        console.log('payer: ', payer);
        console.log('discount: ', discount);
        console.log('transport: ', transport);

        const filteredRest = {
          _id,
          userId,
          fullName,
          payer: this.payment_fields['payer'] ? payer : undefined,
          discount: this.payment_fields['discount'] ? discount : undefined,
          transport: this.payment_fields['transport'] ? transport : undefined,
        };

        return filteredRest;
      });

      const response = await this.request({
        method: 'patch',
        url: 'student/studentProfile/mass-update-payment/',
        data: {
          students,
        },
      });
      if (response) {
        this.handleClose(true);
        eventBus.$emit('open-toast', {
          error: false,
          message: 'Students Transfered successfully',
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.error,
        });
      }
      this.working = false;
    },
    async fetchPayers() {
      let response = await this.request({
        method: 'get',
        url: 'school/payer',
      });
      if (response && !response.error) {
        this.payer_list = response.record;
      } else {
        this.payer_list = [];
      }
    },
    async fetchDiscounts() {
      let response = await this.request({
        method: 'get',
        url: 'school/discount',
      });
      if (response && !response.error) {
        this.discount_list = response.record;
      } else {
        this.discount_list = [];
      }
    },
    async fetchGroupList() {
      let response = await this.request({
        method: 'get',
        url: 'school/group/active',
      });
      if (response && !response.error) {
        this.group_list = response.record;
      } else {
        this.group_list = [];
      }
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc) => {
          acc['userId'] = { required };
          acc['fullName'] = { required };
          acc['grade'] = { required };
          acc['section'] = { required };
          acc['payer'] = { required };
          acc['discount'] = { required };
          acc['transport'] = { required };

          return acc;
        }, {}),
      },
      students: {
        ...this.students.reduce(
          (acc, payee, index) => ({
            ...acc,
            [index]: {
              ...Object.keys(this.formTemplate).reduce(
                (acc, key) => ({ ...acc, [key]: { required } }),
                {}
              ),
            },
          }),
          {}
        ),
      },
    };
  },
  async created() {
    await this.fetchPayers();
    await this.fetchGroupList();
    await this.fetchDiscounts();
  },
};
</script>

<style></style>
