<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title> Issue Book </template>
    <template>
      <div class=" ">
        <div class="flex justify-center space-x-2 items-center">
          <icon name="check-circle" class="text-secondary" />
          <switch-button label="Issue For Myself" v-model="isSelf" />
        </div>
        <div class="grid grid-cols-2 gap-3 my-2">
          <div
            class="border-1 col-span-1 p-4 rounded-default border-gray-100 shadow-sm"
          >
            <span class="font-bold">USER INFORMATION</span>
            <!-- SELECT USER INFO -->
            <base-select
              v-if="!isSelf"
              label="User Type"
              placeholder="User Type"
              v-model="Form.user_type"
              :items="USER_TYPE_OPTIONS"
              searchable
            />
            <user-select
              v-if="!isSelf"
              label="User "
              placeholder="User"
              v-model="Form.user"
              searchable
              :url="SEARCH_URL"
              :label_keys="LABEL_KEYS"
              @detail="updateUserInfo"
              :errors="errors.user"
              :check="check"
              required
            />
            <!-- USER -->
            <div class="py-7 flex justify-center">
              <div
                class="w-32 h-32 rounded-full bg-gray-100 flex justify-center items-center"
              >
                <img
                  v-if="user_info.profilePic"
                  :src="PROFILE_PATH + user_info.profilePic"
                  v-viewer="{ inline: false, movable: true }"
                  class="w-full h-full object-cover rounded-full"
                  alt=""
                  crossorigin="anonymous"
                />
                <icon
                  v-else
                  :responsive="true"
                  name="user"
                  class="w-full h-full"
                ></icon>
              </div>
            </div>
            <div class="py-2 text-center">
              <div class="text-xl capitalize font-semibold">
                {{ user_info.fullName }}
              </div>
              <div class="font-sans">{{ user_info.userId }}</div>

              <div v-if="user_info['phoneNumber']" class="font-sans">
                {{ user_info['phoneNumber'].join(' | ') }}
              </div>
            </div>
          </div>

          <div
            class="col-span-1 border-1 p-4 rounded-default border-gray-100 shadow-sm"
          >
            <span class="font-bold">BOOK INFO</span>

            <div class="py-7 flex justify-center">
              <div
                class="w-32 h-32 rounded-full bg-gray-100 flex justify-center items-center"
              >
                <img
                  v-if="item.cover"
                  :src="IMAGES_PATH + item.cover"
                  v-viewer="{ inline: false, movable: true }"
                  class="object-cover w-full h-full rounded-lg"
                  alt=""
                  crossorigin="anonymous"
                />
                <icon
                  v-else
                  :responsive="true"
                  name="course"
                  class="w-full h-full"
                ></icon>
              </div>
            </div>
            <div class="grid grid-cols-1 gap-2 my-2">
              <div class="flex space-x-2 items-center mx-4">
                <icon name="course" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.name }}
                </p>
              </div>
              <div class="flex space-x-2 items-center mx-4">
                <icon name="user" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.author }}
                </p>
              </div>
              <!-- IF AVAILABLE -->
              <div v-if="item.available > 0 && !isSelf">
                <base-select
                  required
                  bordered
                  searchable
                  label="Book Copy"
                  :items="COPY_OPTIONS"
                  placeholder="copy"
                  v-model="Form.book_copy"
                  :errors="errors.book_copy"
                  :check="check"
                />

                <div v-if="ACTIVE_COPY" class="flex space-x-2 items-center m-4">
                  <p class="text-lg font-semibold text-center capitalize">
                    STATUS
                  </p>
                  <icon name="check" class="text-success" />

                  <p
                    class="text-lg font-semibold text-success text-center capitalize"
                  >
                    Is {{ ACTIVE_COPY.status }}
                  </p>
                </div>

                <div
                  v-if="ACTIVE_COPY"
                  class="flex space-x-2 items-center ml-4"
                >
                  <p class="text-lg font-semibold text-center capitalize">
                    HEALTH
                  </p>

                  <icon
                    name="plus-square"
                    :class="{
                      'text-error': ACTIVE_COPY.health === 'old',
                      'text-success': ACTIVE_COPY.health === 'new',
                      'text-orange': ACTIVE_COPY.health === 'moderate',
                    }"
                  />
                  <p
                    class="text-lg font-semibold text-success text-center capitalize"
                    :class="{
                      'text-error': ACTIVE_COPY.health === 'old',
                      'text-success': ACTIVE_COPY.health === 'new',
                      'text-orange': ACTIVE_COPY.health === 'moderate',
                    }"
                  >
                    {{ ACTIVE_COPY.health }}
                  </p>
                </div>
              </div>

              <!-- IF NOT AVAILABLE -->
              <base-no-data
                v-else-if="item.available < 1"
                nodataClass="text-orange"
                message="ALL AVAILABLE COPIES ARE TAKEN, CLICK ON SAVE TO WAIT ON QUEUE"
              />
            </div>
            <br />
          </div>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import UserSelect from '@/views/protected/library_management/issue/components/UserSelect.vue';
import SwitchButton from '@/components/base/SwitchButton.vue';

import { PROFILE_PATH, IMAGES_PATH } from '@/config/config.js';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { UserSelect, ModalTemplate, SwitchButton },
  props: ['item'],
  mixins: [validation],
  data() {
    return {
      PROFILE_PATH,
      IMAGES_PATH,
      Form: {
        user_type: 'student',
        user: '',
        book: this.item._id,
        book_copy: '',
        description: '',
      },
      isSelf: false,
      search: '',
      user_info: {},
      my_info: {},
      book_copies: [],
      errors: {},
      working: false,
      check: false,
      meta: [
        { key: 'Form.user', label: 'User' },
        { key: 'Form.user_type', label: 'User Type' },
        { key: 'Form.book', label: 'Book' },
        { key: 'Form.book_copy', label: 'Book Copy' },
      ],
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    STEPS() {
      return ['Select User', 'Issue Information'];
    },
    LABEL_KEYS() {
      return ['fullName', 'userId'];
    },
    SELECTED_USER() {
      return ['fullName', 'userId'];
    },

    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },

    USER_TYPE_OPTIONS() {
      return [
        {
          label: 'Student',
          value: 'student',
          icon_name: 'user-graduate',
        },
        {
          label: 'Teacher',
          value: 'teacher',
          icon_name: 'chalkboard-user',
        },
        {
          label: 'School Official',
          value: 'school_official',
          icon_name: 'school',
        },
      ];
    },

    COPY_OPTIONS() {
      return this.book_copies.map((copy) => ({
        label: 'ISBN : ' + copy.isbn,
        value: copy._id,
      }));
    },

    ACTIVE_COPY() {
      return this.book_copies.find((copy) => copy._id == this.Form.book_copy);
    },

    SEARCH_URL() {
      let url;
      switch (this.Form.user_type) {
        case 'school_official':
          url = 'school/official/search';
          break;
        case 'teacher':
          url = 'school/teacherProfile/search';
          break;
        default:
          url = 'student/studentProfile/search';
          break;
      }
      return url;
    },
  },
  watch: {
    'Form.user_type'() {
      this.Form.user = '';
      this.user_info = {};
    },
    isSelf() {
      if (this.isSelf) this.user_info = this.my_info;
      else this.user_info = {};
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['user', 'user_type', 'book'].includes(key)) {
            acc[key] = { required };
          } else if (key == 'book_copy' && this.item.available > 0) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    updateUserInfo(newUserInfo) {
      this.user_info = newUserInfo;
    },

    handleClose(invokeCb) {
      console.log('invokeL ', invokeCb);
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.initial = false;
      this.check = true;
      if (!this.isValid() && !this.isSelf)
        return eventBus.$emit('open-toast', {
          message: 'please check all the fields',
          error: true,
        });
      if (this.isSelf) this.handleSelfIssue();
      else this.handleIssue();
      this.initial = true;
    },
    populateData() {
      Object.keys(this.oldForm).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });

      this.Form['status'] = ['closed', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;
    },
    async handleIssue() {
      this.working = true;

      let response = await this.request({
        method: 'post',
        url: 'school/issue',
        data: this.Form,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: response.message,
          warning: response.warning,
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleSelfIssue() {
      this.working = true;

      let response = await this.request({
        method: 'post',
        url: 'school/issue/request',
        data: { book: this.item._id },
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: response.message,
          warning: response.warning,
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },
    async fetchCopies() {
      let response = await this.request({
        method: 'get',
        url: 'school/bookCopy/active/' + this.item._id,
      });
      if (response && !response.error) {
        this.book_copies = response.record;
        this.activeCopy = response.record[0]._id;
      } else {
        this.book_copies = [];
        this.activeCopy = '';
      }
    },

    async fetchMyInfos() {
      let response = await this.request({
        method: 'get',
        url: 'school/official/me',
      });
      if (response && !response.error) {
        this.my_info = response.record;
      } else {
        this.my_info = {};
      }
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.isUpdate) this.populateData();
    await this.fetchCopies();
    await this.fetchMyInfos();
  },
};
</script>

<style></style>
