import LibraryOverview from '@/views/protected/digital_library/Overview.vue';
import CategoriesList from '@/views/protected/digital_library/CategoriesList.vue';
import BooksList from '@/views/protected/digital_library/BooksList.vue';
export default [
  {
    path: 'overview',
    name: 'library-overview',
    component: LibraryOverview,
  },
  {
    path: 'categories-list',
    name: 'library-categories-list',
    component: CategoriesList,
  },
  {
    path: 'books-list',
    name: 'library-books-list',
    component: BooksList,
  },
];
