<template>
  <div class="bg-white z-10 relative shadow-lg" style="min-height: 320px">
    <!-- cover view -->
    <div
      class="flex z-0 absolute top-0 bottom-40 right-0 left-0 cursor-pointer"
      style="height: 160px"
      @click="handleClick"
    >
      <div class="h-full w-full flex justify-center items-center">
        <img
          v-if="item.cover"
          :src="IMAGES_PATH + item.cover"
          class="w-full h-full object-cover cursor-pointer"
          alt=""
          crossorigin="anonymous"
        />
        <icon
          v-else
          :responsive="true"
          fill="#8993a4"
          name="course"
          class="w-full h-full"
        ></icon>
      </div>
    </div>
    <!-- skew -->
    <div
      class="bg-white z-10 transform -skew-y-6 absolute right-0 left-0 cursor-pointer"
      style="top: 124px; bottom: 24px"
      @click="handleClick"
    ></div>
    <!-- detail view -->
    <div
      class="z-20 absolute bottom-0 bg-orang right-0 left-0 flex flex-col justify-between"
      style="top: 150px"
    >
      <div
        class="px-4 py-2 h-full flex flex-col cursor-pointer"
        @click="handleClick"
      >
        <div class="grid grid-cols-1 my-auto gap-3">
          <div class="flex space-x-2 items-center">
            <icon :size="28" name="course" class="text-secondary" />
            <p class="text-sm font-semibold capitalize">
              {{ item.name }}
            </p>
          </div>
          <div class="flex space-x-2 items-center">
            <icon name="user" class="text-secondary" />
            <p class="text-sm capitalize">{{ item.author }}</p>
          </div>

          <div v-if="item.available > 0" class="flex space-x-2 items-center">
            <div class="w-2 h-2 rounded-full bg-primary"></div>
            <p class="text-sm font-semibold capitalize">
              {{
                item.available == 1
                  ? item.available + ' book available'
                  : item.available + ' books available'
              }}
            </p>
          </div>

          <div v-else class="flex space-x-2 items-center">
            <div class="w-2 h-2 rounded-full bg-orange"></div>

            <p class="text-sm text-orange font-semibold">THIS BOOK IS TAKEN</p>
          </div>

          <div v-if="item.no_issue" class="flex space-x-2 items-center">
            <div class="w-2 h-2 rounded-full bg-orange"></div>

            <p class="text-sm text-orange font-semibold">CAN NOT BE ISSUED</p>
          </div>
        </div>
      </div>
      <div class="flex px-5 py-2 space-x-5 justify-end"></div>
      <div class="w-full bg-grayBg py-2 px-4 flex justify-between items-center">
        <div>
          <div class="flex space-x-1">
            <icon
              name="star-hallow"
              v-for="i in 5"
              :key="i"
              class="text-orange"
              @click="handleClick"
            />
          </div>
        </div>
        <div>
          <base-button
            v-if="!item.no_issue && item.copies > 0"
            label="ISSUE"
            class="rounded-full shadow-xl py-0"
            @click="handleClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IMAGES_PATH } from '@/config/config.js';
import { mapActions } from 'vuex';
import eventBus from '@/eventBus.js';

export default {
  props: {
    item: {
      required: true,
    },
  },
  data() {
    return {
      IMAGES_PATH,
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClick() {
      this.$emit('click');
    },

    handleEdit() {
      let componentProps = {
        course: this.course,
      };
      eventBus.$emit('open-modal', {
        modalId: 4,
        componentProps,
        cb: this.onUpdate,
      });
    },

    onUpdate() {
      this.$emit('updated');
    },
  },
};
</script>

<style></style>
