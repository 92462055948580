<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title> Change User Status </template>
    <template>
      <base-select
        :items="STATUS_OPTIONS"
        label="Select new Status"
        v-model="Form.status"
      />
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '../../eventBus';
import { mapActions, mapState } from 'vuex';

export default {
  components: { ModalTemplate },
  props: ['status', 'id', 'profile_type'],
  data() {
    return {
      Form: {
        status: '',
      },
      STATUS_OPTIONS: [
        {
          label: 'Pending',
          value: 'pending',
        },
        {
          label: 'Verified',
          value: 'verified',
        },
        {
          label: 'Suspended',
          value: 'suspended',
        },
      ],
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      let url = 'reb/official/change-status/' + this.id;

      if (this.profile_type === 'school-officials') {
        url = 'school/official/change-status/' + this.id;
      } else if (this.profile_type === 'teacher') {
        url = 'school/teacherProfile/change-status/' + this.id;
      } else if (this.profile_type === 'student') {
        url = 'student/studentProfile/change-status/' + this.id;
      } else if (this.profile_type === 'parent') {
        url = 'parent/parentProfile/change-status/' + this.id;
      }

      let response = await this.request({
        method: 'put',
        url,
        data: this.Form,
      });
      if (response && !response.error) {
        this.handleClose(true);
        return eventBus.$emit('open-toast', {
          message: response.message,
          warning: response.warning,
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },
  computed: {
    ...mapState('main', ['error']),
  },
  created() {
    this.Form.status = this.status;
  },
};
</script>

<style></style>
