<template>
  <div>
    <base-title :title="'Library Config'" class="mb-2">
      <template #actions>
        <div class="flex space-x-3">
          <base-button
            label="Save"
            icon="check-circle"
            class="px-2"
            code="gr-add"
            @click="handleSave"
          />
        </div>
      </template>
    </base-title>
    <!-- <div class="my-3 space-y-4"> -->
    <div class="bg-white p-12 item-grid rounded-default">
      <div class="flex items-center justify-between w-full">
        <p class="uppercase text-lg text-textMedium tracking-wider">
          Issue Configuration
        </p>
      </div>

      <div
        class="grid grid-cols-2 gap-5 m-4 p-4 text-textMedium border-1 border-gray-200 font-semibold"
      >
        <base-input
          label="Request Expiry (In Days)"
          placeholder=""
          type="number"
          v-model="Form.request_expiry"
          :errors="errors.request_expiry"
          check
        />
        <base-input
          label="Return Duration (In Days)"
          placeholder=""
          type="number"
          v-model="Form.return_duration"
          :errors="errors.return_duration"
          check
        />
        <base-input
          label="Maximum Issue Per User"
          placeholder=""
          type="number"
          v-model="Form.max_issue"
          :errors="errors.max_issue"
          check
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import eventBus from '@/eventBus.js';
import { minValue, required, maxValue } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

// import SwitchButton from '@/components/base/SwitchButton.vue';

export default {
  //   components: { SwitchButton },
  props: ['id'],
  mixins: [validation],
  data() {
    return {
      working: false,
      errors: {},
      Form: {
        request_expiry: 0,
        return_duration: 0,
        max_issue: 0,
      },

      meta: [
        { key: 'Form.request_expiry', label: 'Request Expiry' },
        { key: 'Form.return_duration', label: 'Return Duration' },
        { key: 'Form.max_issue', label: 'Maximum Issue' },
      ],
      initial: true,
    };
  },

  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['request_expiry'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(0),
              maxValue: maxValue(100),
            };
          } else if (['return_duration'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(0),
              maxValue: maxValue(100),
            };
          } else if (['max_issue'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(0),
              maxValue: maxValue(100),
            };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },

  methods: {
    ...mapActions('main', ['request']),

    async fetchConfig() {
      let response = await this.request({
        method: 'get',
        url: 'school/libConfig',
      });
      if (response && !response.error) {
        const lib_config = response.record;

        this.Form.request_expiry = lib_config.request_expiry;
        this.Form.return_duration = lib_config.return_duration;
        this.Form.max_issue = lib_config.max_issue;

        this.Form = { ...this.Form };
      }
    },

    async handleSave() {
      this.working = true;
      this.initial = false;
      if (!this.isValid()) return;
      let response = await this.request({
        method: 'post',
        url: 'school/libConfig',
        data: { ...this.Form },
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: 'Configuration Updated Successfully',
        });
        this.fetchConfig();
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.initial = true;
    },
  },

  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });

    await this.fetchConfig();
  },
};
</script>

<style></style>
