//HOME
// let HOST = 'http://localhost';
// let PORT = '5000';

//HENON-TEST
// let HOST = 'http://196.189.126.213';
// let PORT = '8001';

//CBE
// let HOST = 'http://196.189.126.213';
// let PORT = '4499';

//LION
// let HOST = 'http://196.189.126.213';
// let PORT = '3939';

//WEGAGEN
// let HOST = 'http://196.189.126.213';
// let PORT = '3000';

let HOST = 'https://henonapi.wegagenbanksc.com.et';
let PORT = '';

let API_ENDPOINT = HOST + ':' + PORT;

const BASE_URL = API_ENDPOINT + '/api';
const BILLING_FE_BASE_URL = 'http://localhost:8000/#/';
// const BILLING_BASE_URL = 'http://192.168.0.100:6666/api';

const PROFILE_PATH = API_ENDPOINT + '/profile_pictures/';
const ATTACHMENTS_PATH = API_ENDPOINT + '/attachments/';
const IMAGES_PATH = API_ENDPOINT + '/images/';
const VIDEOS_PATH = API_ENDPOINT + '/videos/';
const FILES_PATH = API_ENDPOINT + '/files/';
const LOGO_PATH = API_ENDPOINT + '/logos/';
const LIBRARY_METADATA = API_ENDPOINT + '/library/metadata/';
const LAB_METADATA_PATH = API_ENDPOINT + '/lab/metadata/';
const SIGNATURE_PATH = BASE_URL + '/signature/';
const ATTACHMENT_PATH = BASE_URL + '/attachments/';
const IO_BASE_URL = API_ENDPOINT;

const TOKEN_KEY = 'token';
const USER_KEY = '_u_k';

export {
  IO_BASE_URL,
  LAB_METADATA_PATH,
  LIBRARY_METADATA,
  BASE_URL,
  IMAGES_PATH,
  VIDEOS_PATH,
  FILES_PATH,
  PROFILE_PATH,
  ATTACHMENTS_PATH,
  SIGNATURE_PATH,
  USER_KEY,
  TOKEN_KEY,
  ATTACHMENT_PATH,
  LOGO_PATH,
  BILLING_FE_BASE_URL,
};
