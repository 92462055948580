import MySchool from '@/views/protected/school_management/MySchool.vue';
import Assessment from '@/views/protected/school_management/assessment/List.vue';
import CommBookManagement from '@/views/protected/school_management/components/CommunicationBookIndex.vue';
import CommBook from '@/views/protected/school_management/components/CommunicationBookConfig.vue';

export default [
  {
    path: 'school-pref',
    name: 'school-pref',
    component: MySchool,
  },

  {
    path: 'assessments',
    name: 'assessments',
    component: Assessment,
  },
  {
    path: 'comm-book',
    name: 'comm-manage',
    component: CommBookManagement,
  },
  {
    path: 'comm-book/create',
    name: 'comm-create',
    component: CommBook,
  },
  {
    path: 'comm-book/update/:id',
    name: 'comm-update',
    component: CommBook,
  },
];
