<template>
  <div>
    <base-title :title="(update ? 'Update' : 'Create') + ' Student'">
      <template #actions>
        <div class="flex space-x-4">
          <base-button
            :primary="false"
            icon="arrow-left"
            label="Go Back"
            class="bg-gray-100 text-textDarkest px-3"
            @click="handleGoBack"
          />
          <base-button
            icon="check-circle"
            :label="update ? 'Update' : 'Create'"
            class="px-3"
            :working="working"
            @click="update ? handleUpdate() : handleCreate()"
          />
        </div>
      </template>
    </base-title>
    <div class="mt-2 bg-white p-5">
      <stepper
        :steps="STEPS"
        @next="handleNext"
        @step="handleStep"
        :working="working"
        @finished="update ? handleUpdate() : handleCreate()"
        :finish_label="update ? 'Update' : 'Create'"
      >
        <template slot="Basic Information">
          <loader type="list" :amount="6" v-if="loading"></loader>
          <div v-else>
            <div class="grid grid-cols-4 gap-3 my-8">
              <div
                class="border-1 p-4 rounded-default border-gray-100 shadow-sm"
              >
                <span class="font-bold">UPLOAD STUDENT PHOTO</span>
                <div
                  class="w-40 h-48 my-8 cursor-pointer mx-auto"
                  @click="handleOpenDialog('profile-input')"
                >
                  <img
                    :src="
                      !profilePicObj
                        ? PROFILE_PATH + Form.profilePic
                        : profilePicObj
                    "
                    alt=""
                    class="object-cover w-full h-full rounded-lg"
                    crossorigin="anonymous"
                  />
                  <div class="mt-8">
                    <input
                      class=""
                      style="display: none"
                      ref="profile-input"
                      @change="
                        handleSelectFile('profile-input', 'Form.profilePic')
                      "
                      type="file"
                      name="file-input"
                    />
                    <div
                      class="bg-primary text-white p-2 w-full rounded-default cursor-pointer text-center mt-4"
                    >
                      Upload Photo
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-span-3 border-1 p-4 rounded-default border-gray-100 shadow-sm"
              >
                <span class="font-bold">PERSONAL</span>
                <div class="grid grid-cols-3 gap-5 my-8">
                  <base-input
                    required
                    bordered
                    label="First Name"
                    placeholder="First name"
                    v-model="Form.firstName"
                    :errors="errors.firstName"
                    capitalize
                    :check="check"
                  />
                  <base-input
                    required
                    label="Father's Name"
                    placeholder="Father's name"
                    v-model="Form.middleName"
                    :errors="errors.middleName"
                    capitalize
                    :check="check"
                  />
                  <base-input
                    label="Grandfather's Name"
                    placeholder="grandfather's name"
                    v-model="Form.lastName"
                    :errors="errors.lastName"
                    capitalize
                  />

                  <base-select
                    required
                    label="Gender"
                    placeholder="gender"
                    v-model="Form.gender"
                    :errors="errors.gender"
                    :items="GENDER_OPTIONS"
                    :check="check"
                  />
                  <base-select
                    required
                    bordered
                    searchable
                    label="Grade Level"
                    :items="GRADE_OPTIONS"
                    placeholder="grade"
                    v-model="Form.class"
                    :errors="errors.class"
                    :check="check"
                  />
                  <base-select
                    bordered
                    searchable
                    label="Section"
                    :items="SECTION_OPTIONS"
                    placeholder="section"
                    v-model="Form.ay_id"
                    :errors="errors.ay_id"
                  />
                  <base-input
                    v-if="isGreg"
                    label="Birth Date"
                    v-model="Form.birthdate"
                    :errors="errors.birthdate"
                    type="date"
                    :check="check"
                  />
                  <base-eth-calendar
                    v-if="!isGreg"
                    label="Birth Date"
                    v-model="Form.birthdate"
                    :errors="errors.birthdate"
                    :isUpdate="update"
                  />
                </div>
                <br />

                <span class="font-bold">ADDRESS</span>
                <div class="grid grid-cols-3 gap-5 my-8">
                  <base-select
                    bordered
                    label="Region"
                    placeholder="region"
                    v-model="Form.region"
                    :errors="errors.region"
                    :items="REGION_OPTIONS"
                    searchable
                  />
                  <base-input
                    label="City"
                    placeholder="city"
                    v-model="Form.city"
                    :errors="errors.city"
                    capitalize
                    :check="check"
                  />
                  <base-input
                    label="Sub City"
                    placeholder="Sub City"
                    v-model="Form.subcity"
                    :errors="errors.subcity"
                    capitalize
                    :check="check"
                  />
                  <base-input
                    label="Street Address"
                    placeholder="Street Address"
                    v-model="Form.address"
                    :errors="errors.address"
                    capitalize
                    :check="check"
                  />
                  <base-input
                    label="House Number"
                    placeholder="House Number"
                    v-model="Form.houseNumber"
                    :errors="errors.houseNumber"
                    capitalize
                    :check="check"
                  />
                </div>
                <br />

                <span class="font-bold">CONTACT INFO</span>
                <div class="grid grid-cols-3 gap-5 my-8">
                  <div class="">
                    <span
                      >Phone Number
                      <span class="text-error">{{ update ? '' : '*' }}</span>
                    </span>
                    <div class="grid grid-cols-6 gap-2">
                      <base-select
                        class="col-span-2"
                        :items="COUNTRY_CODE_OPTIONS"
                        v-model="countryCode"
                        :minWFull="true"
                      />
                      <base-multitype
                        class="col-span-4"
                        placeholder="phone number"
                        v-model="Form.phoneNumber"
                        :items="Form.phoneNumber"
                        :errors="errors.phoneNumber"
                        :check="check"
                      />
                    </div>
                  </div>
                  <base-input
                    label="Email"
                    placeholder="email"
                    v-model="Form.email"
                    :errors="errors.email"
                    :check="check"
                  />
                </div>
                <br />

                <span class="font-bold">PAYMENT</span>
                <div class="grid grid-cols-3 gap-5 my-8">
                  <base-select
                    searchable
                    label="Payer"
                    :items="payer_options"
                    placeholder="select payer"
                    v-model="Form.payer"
                    :errors="errors.payer"
                  />
                  <base-select
                    searchable
                    clearable
                    label="Discount"
                    :items="discount_options"
                    placeholder="select discount"
                    v-model="Form.discount"
                    :errors="errors.discount"
                  />
                  <!-- <base-input
                    label="Account Number"
                    placeholder="Account Number"
                    v-model="Form.accountNumber"
                    :errors="errors.accountNumber"
                    :check="check"
                  /> -->
                </div>

                <div class="grid grid-cols-3 gap-5 my-8">
                  <base-check-box
                    label="Uses transport"
                    :selected="Form.has_transport"
                    @toggle="setTransport"
                  />

                  <base-select
                    v-if="Form.has_transport"
                    label="Transport"
                    :items="GROUP_OPTIONS"
                    placeholder="transport"
                    v-model="Form.transport"
                    :errors="errors.transport"
                    searchable
                    check
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="Academic Background">
          <div
            class="border-1 p-4 pb-12 rounded-default text-center border-gray-100 shadow-sm"
          >
            <span class="">ATTACH STUDENT DOCUMENTS</span>

            <div class="grid grid-cols-4 pb-12 gap-4">
              <div
                class="flex justify-center border-1 border-gray-100 rounded-lg shadow-sm"
              >
                <div class="w-40 my-8">
                  <div
                    class="cursor-pointer"
                    @click="handleOpenDialog(`student-document`)"
                  >
                    <div class="h-48 hover:bg-gray-100 p-1 rounded-lg">
                      <img
                        :src="studentDocumentObj"
                        alt=""
                        ref="display-img"
                        class="object-contain w-full h-full"
                        crossorigin="anonymous"
                      />
                    </div>

                    <div class="mt-8">
                      <input
                        class=""
                        style="display: none"
                        :ref="`student-document`"
                        type="file"
                        @change="uploadDoc"
                        name="document"
                        accept="image/*, .pdf"
                      />
                    </div>
                  </div>
                  <base-input
                    placeholder="Document name..."
                    class="-mt-4"
                    v-model="documentLabel"
                  />
                  <base-button
                    label="Add Document"
                    class="mt-1"
                    @click="handleAddDoc"
                  />
                </div>
              </div>
              <div
                class="col-span-3 border-1 border-gray-100 rounded-lg shadow-sm"
              >
                <div class="grid grid-cols-2">
                  <div class="p-2 border-r-1 border-gray-100">
                    <p class="bg-gray-100 p-1">New attachments</p>
                    <div
                      v-for="(doc, i) in docs"
                      :key="doc.lastModified"
                      class="grid grid-cols-8 mb-1 p-1 border-1 border-gray-100 rounded-default shadow-sm items-center"
                    >
                      <div class="col-span-7 grid grid-cols-8">
                        <div class="">
                          <div v-if="doc.type.includes('image')">
                            <div class="h-12 flex justify-center items-center">
                              <img
                                :src="docImages[i]"
                                class="object-contain w-full h-full"
                                alt=""
                              />
                            </div>
                          </div>
                          <div v-else-if="doc.type.includes('pdf')">
                            <div class="flex justify-center items-center">
                              <img
                                src="@/assets/pdfBlue.png"
                                class="h-12"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-span-7 text-left p-1">
                          <p class="truncate">
                            <span class="font-semibold">File:</span>
                            {{ doc.name }}
                          </p>
                          <p class="">
                            <span class="font-semibold">Label:</span>
                            {{ docLabels[i].label }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="flex justify-center border-l-1 border-gray-200"
                      >
                        <div
                          class="bg-gray-100 p-2 rounded-full mt-1 text-primary cursor-pointer"
                          @click="removeDocLabel(i)"
                        >
                          <icon name="trash-alt" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2">
                    <p class="bg-gray-100 p-1">Existing attachments</p>
                    <div class="" v-if="update">
                      <div
                        v-for="(file, i) in Form.attachments"
                        :key="i"
                        class="grid grid-cols-8 mb-1 p-1 border-1 border-gray-100 rounded-default shadow-sm items-center"
                      >
                        <div class="col-span-7 grid grid-cols-8">
                          <div class="">
                            <div v-if="file.fileType == 'image'">
                              <div class="flex justify-center items-center">
                                <img
                                  :src="`${ATTACHMENTS_PATH}${
                                    file.filePath.split('attachments')[1]
                                  }`"
                                  class="h-12"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div v-else-if="file.fileType == 'pdf'">
                              <div class="flex justify-center items-center">
                                <img
                                  src="@/assets/pdfBlue.png"
                                  class="h-12"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-span-7 text-left p-1">
                            <p class="">
                              <span class="font-semibold">Label:</span>
                              {{ file.fileLabel }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="flex justify-center border-l-1 border-gray-200"
                        >
                          <div
                            class="bg-gray-100 p-2 rounded-full mt-1 text-primary cursor-pointer"
                          >
                            <icon
                              name="trash-alt"
                              @click="handleDeleteAttachment(id, file._id)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="Guardians">
          <div class="py-5">
            <d-table
              #default="{ item, index }"
              :headers="guardianHeaders"
              :items="parentsListObj"
              :hasActions="true"
              :handlers="handlers"
              :buttons="TBUTTONS"
              @search="handleLinkGuardian"
              @add_guardian="addGuardian"
              :hideColumnSort="true"
            >
              <td v-for="(header, i) in guardianHeaders" :key="i">
                <div v-if="header.key === 'phoneNumber'">
                  {{ item['phoneNumber'].join(' | ') }}
                </div>
                <div v-else-if="header.key === 'status'">
                  <status-chip
                    :label="item[header.key]"
                    class="max-w-min"
                    style="max-width: fit-content"
                  />
                </div>
                <div
                  v-else-if="header.key === 'profilePic'"
                  class="flex space-x-3"
                >
                  <div
                    class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                  >
                    <img
                      v-if="item.profilePic"
                      :src="PROFILE_PATH + item.profilePic"
                      class="w-full h-full object-cover rounded-full"
                      alt=""
                      crossorigin="anonymous"
                    />
                    <icon
                      v-else
                      :size="18"
                      name="user"
                      class="w-full h-full"
                    ></icon>
                  </div>
                  <div
                    class="flex space-x-3 uppercase text-primary font-semibold"
                  >
                    <p>{{ item.firstName }}</p>
                    <p>{{ item.middleName }}</p>
                  </div>
                </div>
                <div v-else-if="header.key === 'relationship'">
                  <div class="w-2/3">
                    <base-select
                      v-model="parentsListObj[index].relation"
                      :items="RELATIONSHIP_OPTIONS"
                      :errors="errors.relation"
                      :check="check"
                    />
                  </div>
                </div>
                <div v-else class="px-2">
                  {{ getPropByString(item, header.key) }}
                </div>
              </td>
            </d-table>
          </div>
        </template>
        <div
          v-for="(category, i) in custom"
          :key="i"
          :slot="category.name"
          class="grid grid-cols-3 gap-5 my-8"
        >
          <div v-for="(field, j) in category.fields" :key="j">
            <base-input
              :label="field.name"
              :placeholder="field.name"
              v-model="customs[category._id + '-' + field._id]"
            ></base-input>
          </div>
        </div>

        <template #More>
          <div>{{ mode }} {{ id }}</div>
        </template>
      </stepper>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Stepper from '@/components/Stepper.vue';
import { required, email } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import eventBus from '@/eventBus';
import { PROFILE_PATH, ATTACHMENT_PATH } from '@/config/config.js';
import { getPropByString } from '@/utils';
import objUrl from '@/mixins/objUrl.js';
import DTable from '@/components/table/DTable.vue';
import profilePics from '@/assets/avatars/avatar03.png';
import docPic from '@/assets/upload.svg';
import pdfPic from '@/assets/file.png';

export default {
  components: {
    Stepper,
    DTable,
  },
  props: {
    mode: { type: String, default: 'create' },
    id: { type: String },
  },
  mixins: [validation, objUrl],
  data() {
    return {
      working: false,
      loading: false,
      initial: false,
      check: false,
      pdfPic,
      documentLabel: '',
      docImages: [],
      doc: '',
      docs: [],
      docLabels: [],
      editingDoc: false,
      activeDoc: {},
      activeLabel: {},
      PROFILE_PATH,
      ATTACHMENT_PATH,
      // steps: ['Basic', 'Guardians'],
      categories: [],
      customs: {},
      Form: {
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        birthdate: undefined,
        region: '',
        city: '',
        subcity: '',
        address: '',
        houseNumber: '',
        phoneNumber: [],
        email: '',
        accountNumber: '',
        profilePic: '',
        studentDocument: '',
        payer: '',
        class: '',
        ay_id: '',
        discount: '',
        services: [],
        attachments: [],
        has_transport: false,
        transport: '',
        // school: '',
        // parents: []
      },
      profilePicObj: profilePics,
      studentDocumentObj: docPic,
      countryCode: '+251',
      errors: {},
      meta: [
        { key: 'Form.firstName', label: 'First name' },
        { key: 'Form.middleName', label: 'Middle name' },
        { key: 'Form.gender', label: 'Gender' },
        // { key: 'Form.phoneNumber', label: 'Phone number' },
        { key: 'Form.class', label: 'Grade Level' },
        { key: 'Form.email', label: 'This' },
        { key: 'Form.accountNumber', label: 'Account Number' },
      ],
      payer_options: [],
      discount_options: [],
      gradeList: [],
      sectionList: [],
      serviceList: [],
      group_list: [],
      parentsListObj: [],
      custom: [],

      guardianHeaders: [
        { label: 'User', key: 'profilePic' },
        // { label: 'Role', key: 'role' },
        { label: 'Username', key: 'username' },
        { label: 'Gender', key: 'gender' },
        { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'Relationship', key: 'relationship' },
        { label: 'Status', key: 'status' },
      ],
      guardians: [],
      handlers: [
        // {
        //   label: 'Detail',
        //   icon: 'eye',
        //   handler: this.handleGuardianDetail,
        //   idParam: true,
        // },
        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEditGuardian,
          fullParam: true,
        },
        {
          label: 'Remove',
          icon: 'trash-alt',
          handler: this.handleRemoveGuardian,
          idParam: true,
        },
      ],
      TBUTTONS: [
        {
          label: 'Search',
          value: 'search',
          icon: 'search',
        },
        {
          label: 'Add Guardian',
          value: 'add_guardian',
          icon: 'plus',
        },
      ],
    };
  },

  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['firstName', 'middleName', 'gender', 'class'].includes(key)) {
            acc[key] = { required };
            // } else if (['phoneNumber'].includes(key) && !this.update) {
            //   acc[key] = { required };
          } else if (['email'].includes(key)) {
            acc[key] = { email };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapState('main', ['globalSchoolConfig']),
    isGreg() {
      return (
        this.globalSchoolConfig &&
        this.globalSchoolConfig.calendarType == 'greg'
      );
    },
    RELATIONSHIP_OPTIONS() {
      return [
        { label: 'Father', value: 'father' },
        { label: 'Mother', value: 'mother' },
        { label: 'Brother', value: 'brother' },
        { label: 'Sister', value: 'sister' },
        { label: 'Guardian', value: 'guardian' },
      ];
    },

    STEPS() {
      return [
        'Basic Information',
        'Academic Background',
        'Guardians',
        ...this.categories,
      ];
    },

    PHONE_OPTIONS() {
      return this.gradeList;
    },

    GENDER_OPTIONS() {
      return [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ];
    },
    REGION_OPTIONS() {
      return [
        { label: 'Addis Ababa', value: 'Addis Ababa' },
        { label: 'Afar', value: 'Afar' },
        { label: 'Amahara', value: 'Amahara' },
        { label: 'Beninshangul', value: 'Beninshangul' },
        { label: 'Dire Dawa', value: 'Dire Dawa' },
        { label: 'Gambela', value: 'Gambela' },
        { label: 'Harar', value: 'Harar' },
        { label: 'Oromo', value: 'Oromo' },
        { label: 'Sidama', value: 'Sidama' },
        { label: 'Somali', value: 'Somali' },
        { label: 'Southern Region', value: 'Southern Region' },
        { label: 'Tigray', value: 'Tigray' },
      ];
    },

    GRADE_OPTIONS() {
      return this.gradeList.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },
    SECTION_OPTIONS() {
      return this.sectionList.map((section) => ({
        label: section.section.name,
        value: section._id,
      }));
    },

    COUNTRY_CODE_OPTIONS() {
      return [
        { label: '+251', value: '+251' },
        { label: '+1', value: '+1' },
        { label: '+44', value: '+44' },
        { label: '+32', value: '+32' },
        { label: '+33', value: '+33' },
        { label: '+49', value: '+49' },
        { label: '+30', value: '+30' },
        { label: '+249', value: '+249' },
        { label: '+971', value: '+971' },
        { label: '+41', value: '+41' },
        { label: '+86', value: '+86' },
        { label: '+45', value: '+45' },
        { label: '+253', value: '+253' },
        { label: '+20', value: '+20' },
        { label: '+291', value: '+291' },
      ];
    },

    SERVICE_OPTIONS() {
      return this.serviceList.map((service) => ({
        label: service.name,
        value: service._id,
      }));
    },
    GROUP_OPTIONS() {
      return this.group_list.map((group) => ({
        label: group.name,
        value: group._id,
      }));
    },

    update() {
      if (this.id) return true;
      return false;
    },
  },
  watch: {
    'Form.class'() {
      this.fetchSectionList();
    },
  },
  methods: {
    ...mapActions('main', ['request', 'upload']),
    getPropByString,

    removeDocLabel(i) {
      this.docs.splice(i, 1);
      this.docLabels.splice(i, 1);
      this.docImages.splice(i, 1);
    },
    uploadDoc() {
      this.doc = this.$refs['student-document'].files[0];
      if (this.doc) {
        const docNames = this.docs.map((file) => file.name);
        if (!docNames.includes(this.doc.name)) {
          if (this.doc.type.split('/')[0] == 'image') {
            this.$refs['display-img'].src = URL.createObjectURL(this.doc);
          } else {
            this.$refs['display-img'].src = this.pdfPic;
          }
        } else {
          alert('This file is already uploaded');
        }
      }
    },
    handleAddDoc() {
      const docNames = this.docs.map((file) => file.name);
      if (docNames.includes(this.doc.name)) {
        alert('This file is already uploaded');
        return;
      }
      if (this.doc && this.doc.name) {
        this.docs.push(this.doc);
        this.docImages.push(URL.createObjectURL(this.doc));
        this.docLabels.push({
          label: this.documentLabel ? this.documentLabel : 'Unlabeled',
          docName: this.doc.name,
        });
        this.documentLabel = '';
        this.$refs['display-img'].src = this.studentDocumentObj;
      }
    },

    handleGoBack() {
      this.$router.back();
    },
    setTransport() {
      this.Form.has_transport = !this.Form.has_transport;
    },

    handleEditGuardian(item) {
      let componentProps = { oldForm: item };
      eventBus.$emit('open-modal', {
        modalId: 34,
        componentProps,
        cb: this.updateGuardian,
      });
    },

    updateGuardian({ response }) {
      const id = response._id;
      let index = this.parentsListObj.findIndex((parent) => parent._id == id);
      this.parentsListObj[index] = { ...response, relation: '' };
      this.parentsListObj = [...this.parentsListObj];
    },
    handleRemoveGuardian(id) {
      let index = this.parentsListObj.findIndex((parent) => parent._id == id);
      this.parentsListObj.splice(index, 1);
    },

    async handleDeleteAttachment(sid, aid) {
      let componentProps = {
        title: 'Remove Student Document',
        message: 'Are you sure you want to remove this document?',
      };

      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: async () => {
          let response = await this.request({
            method: 'delete',
            url: `student/studentProfile/attachment/${sid}/${aid}`,
          });

          if (!response.error && response.record) {
            this.Form.attachments = response.record.attachments;
            return eventBus.$emit('open-toast', {
              message: 'Document removed',
            });
          }
        },
      });
    },

    handleNext() {
      this.check = true;
      if (!this.isValid())
        eventBus.$emit('open-toast', {
          message: 'please check all the fields',
          error: true,
        });
      else return eventBus.$emit('proceed', 'next');
    },

    handleStep() {
      this.check = true;
      if (!this.isValid())
        eventBus.$emit('open-toast', {
          message: 'please check all the fields',
          error: true,
        });
      else return eventBus.$emit('proceed', 'step');
    },

    async handleCreate() {
      // return eventBus.$emit('open-modal', { modalId: 3, componentProps: { user: { password: 'kdhfkd7675' } } })
      this.check = true;
      this.initial = false;
      if (!this.isValid())
        return eventBus.$emit('open-toast', {
          message: 'please check all the fields',
          error: true,
        });

      if (this.parentsListObj.length == 0) {
        return eventBus.$emit('open-toast', {
          message: 'you have to register a guardian.',
          error: true,
        });
        // return this.handleNext()
        // return eventBus.$emit('proceed', 1);
      } else if (this.parentsListObj.length > 0) {
        let no_relation = this.parentsListObj.find(
          (parent) => parent.relation == ''
        );
        if (no_relation) {
          return eventBus.$emit('open-toast', {
            message: 'Guardian relation is mandatory',
            error: true,
          });
        }
      }

      const multidatas = ['services', 'phoneNumber', 'attachments'];

      let formData = new FormData();
      Object.keys(this.Form).forEach((key) => {
        if (multidatas.includes(key) && this.Form[key])
          formData.append(key, JSON.stringify(this.Form[key]));
        else if (this.Form[key] != '' && this.Form[key] != undefined)
          formData.append(key, this.Form[key]);
      });

      /** format and insert the parent */
      let formatedParents = this.parentsListObj.map((parent) => ({
        parent: parent._id,
        relation: parent.relation || 'father',
      }));
      formData.append('parents', JSON.stringify(formatedParents));

      /** format and insert the custom fields */
      let formatedCustom = this.custom.map((category) => ({
        name: category.name,
        fields: category.fields.map((field) => {
          return {
            [field.name]: this.customs[category._id + '-' + field._id],
          };
        }),
      }));
      formData.append('custom', JSON.stringify(formatedCustom));

      // attachments
      if (this.docs.length) {
        for (let i = 0; i < this.docs.length; i++) {
          formData.append('new_attachments', this.docs[i]);
        }
        formData.append('docLabels', JSON.stringify(this.docLabels));
      }

      this.working = true;

      let response = await this.upload({
        method: 'post',
        url: 'student/studentProfile',
        data: formData,
      });
      if (response && !response.error) {
        this.$router.push({ name: 'manage-student' });
        eventBus.$emit('open-modal', {
          modalId: 3,
          componentProps: {
            user: response.record,
            userType: 'student',
          },
        });
        return eventBus.$emit('open-toast', {
          message: response.message,
          warning: response.warning,
        });
      } else
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      this.initial = true;
      this.working = false;
    },
    addGuardian() {
      eventBus.$emit('open-modal', {
        modalId: 34,
        cb: this.linkGuardian,
      });
    },
    linkGuardian({ response }) {
      this.parentsListObj.push({ ...response, relation: '' });
    },

    populateForm(data) {
      this.Form = {
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        gender: data.gender,
        birthdate: data.birthdate,
        region: data.region,
        city: data.city,
        subcity: data.subcity,
        address: data.address,
        houseNumber: data.houseNumber,
        phoneNumber: data.phoneNumber,
        email: data.email,
        profilePic: data.profilePic,
        class: data.class && data.class._id ? data.class._id : data.class,
        ay_id:
          data.academic_year && data.academic_year._id
            ? data.academic_year._id
            : data.academic_year,
        services: data.services,
        payer: data.payer,
        discount: data.discount,
        has_transport: data.has_transport,
        transport: data.transport,
        attachments: data.attachments,
      };
      console.log('this.Form: ', this.Form);
      /// NOTE:  populate the parent object
      this.parentsListObj = data.parents.map(({ parent, relation }) => ({
        relation,
        ...parent,
      }));

      /// NOTE:  populate the custom object
      // this.custom = data.custom;

      for (const category of data.custom) {
        for (const field of category.fields) {
          for (const key in field) {
            if (key != 'id') {
              this.customs[category.id + '-' + field.id] = field[key];
            }
          }
        }
      }
    },

    async handleFetchUser() {
      let response = await this.request({
        method: 'get',
        url: 'student/studentProfile/' + this.id,
      });
      if (response && !response.error) {
        this.populateForm(response.record);
      }
    },

    async handleUpdate() {
      this.check = true;
      this.initial = false;
      if (!this.isValid())
        return eventBus.$emit('open-toast', {
          message: 'please check all the fields',
          error: true,
        });

      // if (this.parentsListObj.length == 0) {
      //   return eventBus.$emit('open-toast', {
      //     message: 'you have to register a guardian.',
      //     error: true,
      //   });
      //   // return this.handleNext()
      //   // return eventBus.$emit('proceed', 1);
      // } else
      if (this.parentsListObj.length > 0) {
        let no_relation = this.parentsListObj.find(
          (parent) => parent.relation == ''
        );
        if (no_relation) {
          return eventBus.$emit('open-toast', {
            message: 'Guardian relation is mandatory',
            error: true,
          });
        }
      }

      if (typeof this.Form.profilePic == 'string') delete this.Form.profilePic;

      const multidatas = ['services', 'phoneNumber', 'attachments'];

      // if (!this.profilePicObj) delete this.Form.profilePic;
      if (!this.Form.discount) delete this.Form.discount;

      let formData = new FormData();
      Object.keys(this.Form).forEach((key) => {
        if (multidatas.includes(key) && this.Form[key])
          formData.append(key, JSON.stringify(this.Form[key]));
        else if (this.Form[key] != '' && this.Form[key] != undefined)
          formData.append(key, this.Form[key]);
      });

      /** format and insert the parent */
      let formatedParents = this.parentsListObj.map((parent) => ({
        parent: parent._id,
        relation: parent.relation,
      }));
      formData.append('parents', JSON.stringify(formatedParents));

      /** format and insert the custom fields */
      let formatedCustom = this.custom.map((category) => ({
        id: category._id,
        name: category.name,
        fields: category.fields.map((field) => {
          return {
            id: field._id,
            [field.name]: this.customs[category._id + '-' + field._id],
          };
        }),
      }));
      formData.append('custom', JSON.stringify(formatedCustom));

      // attachments
      if (this.docs.length) {
        for (let i = 0; i < this.docs.length; i++) {
          formData.append('new_attachments', this.docs[i]);
        }
        formData.append('docLabels', JSON.stringify(this.docLabels));
      }

      this.working = true;

      let response = await this.upload({
        method: 'put',
        url: 'student/studentProfile/' + this.id,
        data: formData,
      });
      if (response && !response.error) {
        this.$router.push({ name: 'manage-student' });
        return eventBus.$emit('open-toast', {
          message: response.message,
          warning: response.warning,
        });
      } else
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });

      this.initial = true;
      this.working = false;
    },

    async fetchPayers() {
      let response = await this.request({
        method: 'get',
        url: 'school/payer',
      });
      if (response && !response.error) {
        this.payer_options = response.record.map(
          ({ _id: value, name: label }) => ({ label, value })
        );
      }
      this.Form.payer = this.payer_options[0].value;
    },
    async fetchDiscounts() {
      let response = await this.request({
        method: 'get',
        url: 'school/discount',
      });
      if (response && !response.error) {
        this.discount_options = response.record.map(
          ({ _id: value, name: label }) => ({ label, value })
        );
      }
    },
    async fetchGradeList() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active/',
      });
      if (response && !response.error) {
        this.gradeList = response.record;
      }
    },
    async fetchSectionList() {
      this.sectionList = [];
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/class/' + this.Form.class,
      });
      if (response && !response.error) {
        this.sectionList = response.record;
      }
    },
    async fetchServiceList() {
      let response = await this.request({
        method: 'get',
        url: 'school/paymentType/active-services',
      });
      if (response && !response.error) {
        this.serviceList = response.record;
      }
    },
    async fetchGroupList() {
      let response = await this.request({
        method: 'get',
        url: 'school/group/active',
      });
      if (response && !response.error) {
        this.group_list = response.record;
      } else {
        this.group_list = [];
      }
    },
    async getCustomFields() {
      let response = await this.request({
        method: 'get',
        url: 'school/config',
      });
      if (response && !response.error) {
        const customs = response.record.student_custom_fields;
        this.custom = customs;
        for (const cat of customs) {
          for (const field of cat.fields) {
            this.customs[cat._id + '-' + field._id] = '';
          }
        }
        this.categories = customs.map((category) => category.name);
      } else {
        this.item = {};
      }
    },

    handleLinkGuardian() {
      let componentProps = {
        usertype: 'Parent',
      };
      eventBus.$emit('open-modal', {
        modalId: 7,
        componentProps,
        cb: this.onGuardianSelected,
      });
    },

    onGuardianSelected({ response }) {
      let parent_exists = this.parentsListObj.find(
        (parent) => parent._id == response.customer._id
      );
      if (!parent_exists) {
        this.parentsListObj.push({ ...response.customer, relation: '' });
      } else {
        eventBus.$emit('open-toast', {
          message: 'selected guardian is already added',
          error: true,
        });
      }
    },
    async populateDefaultForms() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile/my-school',
      });
      if (response && !response.error) {
        const school = response.record;
        this.Form['region'] = school['region'];
        this.Form['city'] = school['city'];
      }
    },
  },

  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });

    this.loading = true;
    await this.getCustomFields();
    await this.fetchGradeList();
    await this.fetchPayers();
    await this.fetchServiceList();
    await this.fetchGroupList();
    await this.fetchDiscounts();

    if (this.update) await this.handleFetchUser();
    else await this.populateDefaultForms();
    this.loading = false;

    if (this.Form.profilePic) {
      this.profilePicObj = this.PROFILE_PATH + this.Form.profilePic;
    }
  },
};
</script>

<style></style>
