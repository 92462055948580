<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title> Issue Book </template>
    <template>
      <div class=" ">
        <!-- <div class="flex justify-center space-x-2 items-center">
          <icon name="course" class="text-secondary" />
          <p class="text-lg text-center capitalize">{{ item.name }}</p>
        </div> -->
        <div class="grid grid-cols-2 gap-3 my-2">
          <div
            class="border-1 col-span-1 p-4 rounded-default border-gray-100 shadow-sm"
          >
            <span class="font-bold">USER INFORMATION</span>
            <!-- SELECT USER INFO -->
            <!-- USER -->
            <div class="py-7 flex justify-center">
              <div
                class="w-32 h-32 rounded-full bg-gray-100 flex justify-center items-center"
              >
                <img
                  v-if="item.user.profilePic"
                  :src="PROFILE_PATH + item.user.profilePic"
                  v-viewer="{ inline: false, movable: true }"
                  class="w-full h-full object-cover rounded-full"
                  alt=""
                  crossorigin="anonymous"
                />
                <icon
                  v-else
                  :responsive="true"
                  name="user"
                  class="w-full h-full"
                ></icon>
              </div>
            </div>
            <div class="py-2 text-center">
              <div class="text-xl capitalize font-semibold">
                {{ item.user.fullName }}
              </div>
              <div class="font-sans">{{ item.user.userId }}</div>

              <div v-if="item.user['phoneNumber']" class="font-sans">
                {{ item.user['phoneNumber'].join(' | ') }}
              </div>
            </div>
          </div>

          <div
            class="col-span-1 border-1 p-4 rounded-default border-gray-100 shadow-sm"
          >
            <span class="font-bold">BOOK INFO</span>
            <div class="grid grid-cols-1 gap-2 my-2">
              <div class="flex space-x-2 items-center mx-4">
                <icon name="course" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.book.name }}
                </p>
              </div>
              <div class="flex space-x-2 items-center mx-4">
                <icon name="user" class="text-secondary" />
                <p class="text-lg font-semibold text-center capitalize">
                  {{ item.book.author }}
                </p>
              </div>
              <!-- IF AVAILABLE -->
              <div v-if="item.book.available > 0">
                <base-select
                  required
                  bordered
                  searchable
                  label="Book Copy"
                  :items="COPY_OPTIONS"
                  placeholder="copy"
                  v-model="Form.book_copy"
                  :errors="errors.book_copy"
                  :check="check"
                />

                <div v-if="ACTIVE_COPY" class="flex space-x-2 items-center m-4">
                  <p class="text-lg font-semibold text-center capitalize">
                    STATUS
                  </p>
                  <icon name="check" class="text-success" />

                  <p
                    class="text-lg font-semibold text-success text-center capitalize"
                  >
                    Is {{ ACTIVE_COPY.status }}
                  </p>
                </div>

                <div v-if="ACTIVE_COPY" class="flex space-x-2 items-center m-4">
                  <p class="text-lg font-semibold text-center capitalize">
                    HEALTH
                  </p>

                  <icon
                    name="plus-square"
                    :class="{
                      'text-error': ACTIVE_COPY.health === 'old',
                      'text-sucess': ACTIVE_COPY.health === 'new',
                      'text-orange': ACTIVE_COPY.health === 'moderate',
                    }"
                  />
                  <p
                    class="text-lg font-semibold text-success text-center capitalize"
                    :class="{
                      'text-error': ACTIVE_COPY.health === 'old',
                      'text-sucess': ACTIVE_COPY.health === 'new',
                      'text-orange': ACTIVE_COPY.health === 'moderate',
                    }"
                  >
                    {{ ACTIVE_COPY.health }}
                  </p>
                </div>
              </div>

              <!-- IF NOT AVAILABLE -->
              <base-no-data
                v-else
                nodataClass="text-orange"
                message="ALL AVAILABLE COPIES ARE TAKEN, CLICK ON SAVE TO WAIT ON QUEUE"
              />
            </div>
            <br />
          </div>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate },
  props: ['item'],
  mixins: [validation],
  data() {
    return {
      PROFILE_PATH,
      Form: {
        book_copy: '',
        description: '',
      },
      errors: {},
      book_copies: [],

      working: false,
      check: false,
      meta: [{ key: 'Form.book_copy', label: 'Book Copy' }],
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    HEALTH_OPTIONS() {
      return [
        { label: 'New', value: 'new' },
        { label: 'Moderate', value: 'moderate' },
        { label: 'Old', value: 'old' },
      ];
    },
    COPY_OPTIONS() {
      return this.book_copies.map((copy) => ({
        label: 'ISBN : ' + copy.isbn,
        value: copy._id,
      }));
    },

    ACTIVE_COPY() {
      return this.book_copies.find((copy) => copy._id == this.Form.book_copy);
    },

    USER_ICON() {
      return {
        student: 'user-graduate',
        teacher: 'chalkboard-user',
        school_official: 'school',
      };
    },
  },

  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['book_copy'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),

    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.initial = false;
      this.check = true;
      if (!this.isValid())
        return eventBus.$emit('open-toast', {
          message: 'please check all the fields',
          error: true,
        });
      this.handleApprove();
      this.initial = true;
    },

    async handleApprove() {
      this.working = true;

      let response = await this.request({
        method: 'put',
        url: 'school/issue/approve/' + this.item._id,
        data: this.Form,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: response.message,
          warning: response.warning,
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async fetchCopies() {
      let response = await this.request({
        method: 'get',
        url: 'school/bookCopy/active/' + this.item.book._id,
      });
      if (response && !response.error) {
        this.book_copies = response.record;
        this.activeCopy = response.record[0]._id;
      } else {
        this.book_copies = [];
        this.activeCopy = '';
      }
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    await this.fetchCopies();
  },
};
</script>

<style></style>
