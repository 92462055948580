import LabOverview from '@/views/protected/digital_lab/Overview.vue';
import CategoriesList from '@/views/protected/digital_lab/CategoriesList.vue';
import SimulationList from '@/views/protected/digital_lab/SimulationList.vue';
export default [
  {
    path: 'overview',
    name: 'lab-overview',
    component: LabOverview,
  },
  {
    path: 'categories-list',
    name: 'lab-categories-list',
    component: CategoriesList,
  },
  {
    path: 'simulation-list',
    name: 'lab-books-list',
    component: SimulationList,
  },
];
